import React from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Rectangle61 from '../../assets/images/Rectangle61.jpg';

const NewMarkets = () => {
    const isMobileOrTab = useMediaQuery({ query: '(max-width: 900px'})
    return(
        <div style={{backgroundColor: '#f3f3f3'}}>
            <Row >
                <Col xl={{span: 13,offset: 1 }}  className="res-col2" >
                    <h1>Attaquer de nouveaux marchés</h1>
                    <p>
                        Dans une économie toujours plus concurrentielle, la transformation numérique permettra à
                        votre entreprise de s'implanter sur de nouveaux marchés et de s'inscrire dans un plan de
                        croissance durable.
                    </p>
                    <p>
                        Etudes de marché, Business Models, Business Plans et recherches de capitaux, nous vous guidons depuis la préparation jusqu'à l'exécution de votre stratégie croissance.
                    </p>
                    <br/>
                    <p>
                        Donnez à votre entreprise une nouvelle dimension !
                    </p>
                </Col>
                <Col lg={{span: 9, offset: 1}} >
                  <img
                    src={Rectangle61}
                    alt="transformation numerique des entreprises"
                    className="formation-images"
                  />
                </Col>
            </Row>
        </div>
    );
}
export default NewMarkets;