import React from 'react';
import { Link } from 'react-router-dom'
import image from '../../assets/images/candidaturespontanee.webp';
import imageBis from '../../assets/images/safari/candidaturespontanee.png';

import WrapperFormCandidature from './FormulaireCandidature';
import Bandeau from '../Bandeau/bandeau.jsx';

const Candidature = () => {

  return (
    <div>
      <Bandeau
        image={navigator.userAgent.includes('Safari') ? imageBis : image}
        title="Candidature Spontanée"
        text="On cherche également des talents cachés"
      />
      <div style={{ zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd' }}>
        <div style={{ backgroundColor: '#fdfdfd', height: 1 }}></div>
        <h2 id="offre_barre">Formulaire de candidature</h2>
        <div style={{ backgroundColor: '#fdfdfd', position: 'relative', zIndex: 2 }}>
          <WrapperFormCandidature offre={""} />
        </div>
        <Link to='/recrutement/offres' className="linkreturn">
          <h2 id="link_content" style={{ marginTop: 25 }}>Si vous avez changé d'avis, vous pouvez toujours consultez nos offres</h2>
          <h1 id="link_title"> {"> Consultez les offres <"}</h1>
        </Link>
      </div>
    </div>
  )
}

export default Candidature;