import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import showdown from 'showdown';
import { useMediaQuery } from 'react-responsive';
import Bandeau from '../Bandeau/bandeau.jsx'
import WrapperFormCandidature from './FormulaireCandidature'
import image2 from '../../assets/images/category2.webp';
import image1 from '../../assets/images/offreemploi1.webp';
import image2Bis from '../../assets/images/safari/category2.png';
import image1Bis from '../../assets/images/safari/offreemploi1.png';

const OffreDetail = (props) => {
  const offre = props.location.state
  const [image, setImage] = useState(navigator.userAgent.includes('Safari') ? image1Bis : image1)
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  useEffect(() => {
    if (offre.developpeur) {
      setImage(navigator.userAgent.includes('Safari') ? image2Bis : image2)
    }
  })


  const converter = new showdown.Converter()
  const description = converter.makeHtml(offre.description);


  return (
    <div className="candidature">
      {isMobile ? (<><Bandeau
        image={image}
        title=""
        text=""
      /><p style={{ position: 'absolute', top: 100, marginLeft: 30, marginRight: 30, color: "#fff", textAlign: 'center', fontSize: 20 }}>{offre.title}</p></>)
        :
        (<Bandeau
          image={image}
          title={offre.title}
          text=""
        />)
      }
      {/* <img src={image} />
    <div className="title_candidature">
    <h1 className="intitule_offre_title">{offre.title}</h1>
    </div> */}
      <div style={{ zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd' }}>
        <div style={{ backgroundColor: '#fdfdfd', height: 1 }}></div>
        <h2 id="offre_barre">Description du poste</h2>
        <div style={{ backgroundColor: '#fdfdfd', position: 'relative', zIndex: 2 }}>
          <div className="texteoffre">
            <p dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
        <h2 id="offre_barre">Candidater</h2>
        <WrapperFormCandidature offre={offre} />
        <Link to='/recrutement' className="linkreturn">
          <h2 id="link_content">Ce poste ne vous plaît pas ? Nous en avons plusieurs autres !</h2>
          <h1 id="link_title"> {"> Consultez d'autres Offres <"}</h1>
        </Link>
      </div>
    </div>
    // <div className="offredetail">
    //   <img src={image} />
    //   <div className="intitule_offre">
    //     <h1 className="intitule_offre_title">{offre.title}</h1>
    //   </div>
    //   <div style={{zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd'}}>
    //     <div style={{backgroundColor: '#fdfdfd', height: 1 }}></div>
    //     <h2 id="offre_barre">Description du poste</h2>
    //     <div style={{backgroundColor: '#fdfdfd', position: 'relative', zIndex: 2}}>
    //       <div className="texteoffre">
    //         <p dangerouslySetInnerHTML={{__html: description}} />
    //        </div>
    //       <h2 id="offre_barre">Candidater</h2>
    //       <FormCandidature offre={offre}/>
    //       <Link to='/recrutement'>
    //       <h2 id="link_content">Ce poste ne vous plaît pas ? Nous en avons plusieurs autres !</h2>
    //       <h1 id="link_title"> {"> Consultez d'autres Offres <"}</h1>
    //       </Link>
    //     </div>
    //   </div>
    // </div>
  )
}
export default OffreDetail