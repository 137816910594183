import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import '../../CSS/particles.css';

const ParticlesBackground = () => {
        return(
            <div className="home-bloc1">
                <div className="img-bloc1">
                    <div className="img-accueil" />
                </div>
                <div className="text-bloc1">
                    <h1>STRATEG.IN</h1>
                    <h2> Développer votre entreprise. Chaque jour. </h2>
                   <h3>Avec la transformation numérique.</h3>
                    <h4>
                    Dirigeants de PME : nous vous permettons de générer de la croissance grâce
                    aux outils de la transformation numérique, en alliant le pragmatisme des petites
                    entreprises au dynamisme des startups.
                    </h4>
                    <Link to='/offres'>
                    <Button type="primary" size="large" id='savoir-plus'>EN SAVOIR PLUS</Button>
                    </Link>
                    <div class="scroll-downs">
                        <div class="mousey">
                            <div class="scroller"></div>
                        </div>
                    </div>
                </div>
            </div>
        );

}
export default ParticlesBackground;

