import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import BasicLayout from '../layouts/BasicLayout';
import './styles.css';
import img404 from '../../assets/images/404.svg';

const Exception = () => {
  return (
    <BasicLayout>
      <div className="exception">
        <div className="imgBlock">
          <div
            className="imgEle"
            style={{
              backgroundImage: `url(${img404})`
            }}
          />
        </div>
        <div className="content">
          <h1>404</h1>
          <div className="desc">
            Sorry, the page you visited does not exist.
          </div>
          <div className="actions">
            <Link to="/">
              <Button type="primary">Back to home</Button>
            </Link>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Exception;
