import React from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Monitoring2_1 from '../../assets/images/icons/Design_esthe_icon_2.png';
import Like2_1 from '../../assets/images/icons/heart_icon_2.png';
import Operator2_1 from '../../assets/images/icons/maintenance_icon.png';
import Optimizationclock2_1 from '../../assets/images/icons/horloge_icon_2.png';
import SmartphoneAndTablet2_1 from '../../assets/images/icons/responsive_design_icon_2.png';
import Network from '../../assets/images/icons/icon_dev_sur-mesure_2.png';
import '../../CSS/offrespage.css';

const AGILE = () => {
  const isiPade = useMediaQuery({ query: '(max-width: 1200px) and (min-width: 500px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 500px) ' });

  return (
    <div >
      {!isiPade ? 
      <div>
      <Row
        className="row-agile"
        style={{
          alignItems: 'center',
          marginBottom: '10px'
        }}
        type="flex"
      >
        <Col xl={{ span: 2, offset: 1 }} >
          <img src={Like2_1} alt="reflexion stratégique" className="agile-img"  style={{objectFit : 'contain'}}/>
        </Col>
        <Col xl={{ span: 4 }} xs={{span: 24}}  className="col-agile" >
          <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Réflexion stratégique</h3>
          <p>
            Lors de la phase de conception, nous travaillons avec vous afin de créer
            l'outil le plus efficient possible.
          </p>
        </Col>
        <Col xl={{ span: 2, offset: 2 }} >
          <img src={Network} alt="outil web sur mesure" className="agile-img"  style={{objectFit : 'contain'}}/>
        </Col>
        <Col xl={{ span: 4 }} xs={{span: 24}}  className="col-agile">
          <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Sur-mesure</h3>
          <p>
            Lorsque la solution que vous recherchez n'existe pas sur le marché,
            nous développons votre outil web sur-mesure et adapté aux besoins de
            votre structure.
          </p>
        </Col>
        <Col xl={{ span: 2, offset: 2 }} >
            <img src={SmartphoneAndTablet2_1} alt="application respoonsive design" className="agile-img" style={{objectFit : 'contain'}}/>
        </Col>
        <Col xl={{ span: 4 }} xs={{span: 24}} className="col-agile">
          <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Responsive Design</h3>
          <p>
            Toutes nos applications sont nativement optimisées pour tous types
            d'écrans : du smartphone à la TV.
          </p>
        </Col>
      </Row>

      <Row
        style={{
          paddingBottom: '30px',
          alignItems: 'center'
        }}
        type="flex"
      >
        <Col xl={{ span: 2, offset: 1 }} >
            <img src={Optimizationclock2_1} alt="rapidité methodologie agile" className="agile-img" style={{objectFit : 'contain'}}/>
        </Col>
        <Col xl={{ span: 4 }} xs={{span: 24}} className="col-agile">
          <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Rapidité</h3>
          <p>
            Grâce à la méthodologie agile, nous sommes en capacité d'assurer un développement rapide.
          </p>
        </Col>

        <Col xl={{ span: 2, offset: 2 }} >
            <img src={Monitoring2_1} alt="design et esthetique" className="agile-img"  style={{objectFit : 'contain'}}/>
        </Col>
        <Col xl={{ span: 4 }} xs={{span: 24}} className="col-agile">
          <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Design & Esthétique</h3>
          <p>
            Aujourd'hui, développer des outils performants ne suffit plus : les
            utilisateurs veulent des applications agréables à utiliser. Nous en
            faisons notre priorité.
          </p>
        </Col>

        <Col xl={{ span: 2, offset: 2 }} >
            <img src={Operator2_1}  alt="deploiement et maintenance" className="agile-img" style={{objectFit : 'contain'}}/>
        </Col>
        <Col xl={{ span: 4 }} xs={{span: 24}} className="col-agile">
          <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Déploiement et maintenance</h3>
          <p>
            Une fois le projet terminé, nous restons à vos côtés pour vous former
            à l'utilisation et assurer une transition efficace pour votre
            activité.
          </p>
        </Col>
      </Row>
      </div>
      :  isMobile?
          <div>
            <Row
            >
              <Col span={24} offset={11}>
                <img src={Like2_1} alt="reflexion stratégique" className="agile-img"/>
              </Col>
              <Col span={24} className="col-agile" >
                <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Réflexion stratégique</h3>
                <p>
                  Lors de la phase de conception, nous travaillons avec vous afin de créer
                  l'outil le plus efficient possible.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24} offset={11}>
                <img src={Network} alt="outil web sur mesure" className="agile-img"/>
              </Col>
              <Col span={24} className="col-agile">
                <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Sur-mesure</h3>
                <p>
                  Lorsque la solution que vous recherchez n'existe pas sur le marché,
                  nous développons votre outil web sur-mesure et adapté aux besoins de
                  votre structure.
                </p>
              </Col>
            </Row>
            <Row
            >
              <Col span={24} offset={11} >
                <img src={SmartphoneAndTablet2_1} alt="application respoonsive design" className="agile-img"/>
              </Col>
              <Col span={24} className="col-agile">
                <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Responsive Design</h3>
                <p>
                  Toutes vos applications sont nativement optimisées pour tout type
                  d'écran : du smartphone à la TV.
                </p>
              </Col>
            </Row>
              <Row>
              <Col span={24} offset={11}>
                <img src={Optimizationclock2_1} alt="methodologie agile rapidité" className="agile-img"/>
              </Col>
              <Col span={24} className="col-agile">
                <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Rapidité</h3>
                <p>
                  Grâce à la méthodologie agile, nous sommes en capacité d'assurer un développement rapide.
                </p>
              </Col>
            </Row>

            <Row
            >

              <Col span={24} offset={11}>
                <img src={Monitoring2_1} alt="design et esthétique" className="agile-img"/>
              </Col>
              <Col span={24} className="col-agile">
                <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Design & Esthétique</h3>
                <p>
                  Aujourd'hui, développer des outils performants ne suffit plus : les
                  utilisateurs veulent des applications agréables à utiliser. Nous en
                  faisons notre priorité.
                </p>
              </Col>
            </Row>
            <Row>
              <Col span={24} offset={11}>
                <img src={Operator2_1}  alt="deploiement et maintenance" className="agile-img"/>
              </Col>
              <Col span={24} className="col-agile">
                <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Déploiement et maintenance</h3>
                <p>
                  Une fois le projet terminé, nous restons à vos côtés pour vous former
                  à l'utilisation et assurer une transition efficace pour votre
                  activité.
                </p>
              </Col>
            </Row>
          </div>
          :
      <div>
        <Row
      style={{
        marginTop: '60px',
        alignItems: 'center',
        marginBottom: '20px'
      }}
      type="flex"
      className="row-agile"
    >
      <Col span={2}>
        <img src={Like2_1} alt="reflexion stratégique" className="agile-img" style={{objectFit : 'contain'}}/>
      </Col>
      <Col span={10} className="col-agile" >
        <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Réflexion stratégique</h3>
        <p>
          Lors de la phase de conception, nous travaillons avec vous afin de créer
          l'outil le plus efficient possible.
        </p>
      </Col>
      <Col span={2}>
        <img src={Network} alt="outil web sur mesure" className="agile-img" style={{objectFit : 'contain'}}/>
      </Col>
      <Col span={10} className="col-agile">
        <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Sur-mesure</h3>
        <p>
          Lorsque la solution que vous recherchez n'existe pas sur le marché,
          nous développons votre outil web sur-mesure et adapté aux besoins de
          votre structure.
        </p>
      </Col>
      </Row>
      <Row
      style={{
        alignItems: 'center',
        marginBottom: '20px'
      }}
      type="flex"
      className="row-agile"
    >
      <Col span={2} >
          <img src={SmartphoneAndTablet2_1} alt="application respoonsive design" className="agile-img" style={{objectFit : 'contain'}}/>
      </Col>
      <Col span={10} className="col-agile">
        <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Responsive Design</h3>
        <p>
          Toutes nos applications sont nativement optimisées pour tout type
          d'écran : du smartphone à la TV.
        </p>
      </Col>
      <Col span={2}>
          <img src={Optimizationclock2_1} alt="methodologie agile rapidité" className="agile-img" style={{objectFit : 'contain'}}/>
      </Col>
      <Col span={10} className="col-agile">
        <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Rapidité</h3>
        <p>
          Grâce à la méthodologie agile, nous sommes en capacité d'assurer un développement rapide.
        </p>
      </Col>
    </Row>

    <Row
      style={{
        paddingBottom: '60px',
        alignItems: 'center'
      }}
      type="flex"
      className="row-agile"
    >


      <Col span={2}>
          <img src={Monitoring2_1} alt="design et esthétique" className="agile-img" style={{objectFit : 'contain'}}/>
      </Col>
      <Col span={10} className="col-agile">
        <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Design & Esthétique</h3>
        <p>
          Aujourd'hui, développer des outils performants ne suffit plus : les
          utilisateurs veulent des applications agréables à utiliser. Nous en
          faisons notre priorité.
        </p>
      </Col>

      <Col span={2}>
          <img src={Operator2_1}  alt="deploiement et maintenance" className="agile-img" style={{objectFit : 'contain'}}/>
      </Col>
      <Col span={10} className="col-agile">
        <h3 style={{fontWeight: 'bold', color: '#063AA9'}}>Déploiement et maintenance</h3>
        <p>
          Une fois le projet terminé, nous restons à vos côtés pour vous former
          à l'utilisation et assurer une transition efficace pour votre
          activité.
        </p>
      </Col>
    </Row>
    </div>
      }
    </div>
  );
};

export default AGILE;
