import React, { useState } from 'react';
import { Menu, Button } from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import BurgerIcon from './BurgerIcon.jsx';
import '../../CSS/mobilemenu.css';

export default function MobileMenu() {
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = () => {
    setOpenMenu(!openMenu);
  };
  if (openMenu == true) {
    if (window.pageYOffset > 0) {
    } else {
      window.scrollTo(1, 1);
    }
  }

  return (
    <div className="mobile-menu">
      {/* <Button className='mobile-button' onClick={handleClick}> */}
      <div onClick={handleClick}>
        <BurgerIcon openMenu={openMenu} />
      </div>
      {/* { !openMenu ? <MenuOutlined style={{fontSize: 30}}/> : <CloseOutlined style={{fontSize: 30}} />} */}
      {/* </Button> */}
      <CSSTransition
        in={openMenu}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <Menu className="menu-open">
          <Menu.Item key="1" onClick={handleClick}>
            <Link to="/">Accueil</Link>
          </Menu.Item>
          <Menu.Item key="2" onClick={handleClick}>
            <Link to="/offres">Offres</Link>
          </Menu.Item>
          <Menu.Item key="3" onClick={handleClick}>
            <Link to="/equipes">Equipe & Partenaires</Link>
          </Menu.Item>
          <Menu.Item key="4" onClick={handleClick}>
            <Link to="/recrutement">Recrutement</Link>
          </Menu.Item>
          <Menu.Item key="5" onClick={handleClick}>
            <Link to="/blog">Blog</Link>
          </Menu.Item>
          <Menu.Item key="6">
            <Link to="#contact">Contactez-nous</Link>
          </Menu.Item>
        </Menu>
      </CSSTransition>
    </div>
  );
}
