import React from 'react';
import { Row, Col } from 'antd';
import image from '../../assets/images/paper-3213924.webp';
import imageBis from '../../assets/images/safari/paper-3213924.jpg';
import Bandeau from '../Bandeau/bandeau.jsx';
import '../../CSS/equipespartenaires.css';

const Equipes = props => {
  return (
    <div>
      <Bandeau
        image={navigator.userAgent.includes('Safari') ? imageBis : image}
        title="Equipe & Partenaires"
      />
      <div
        className="decouvrez_equipe"
        style={{ zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd' }}
      >
        <div style={{ backgroundColor: '#fdfdfd', height: 1 }}></div>
        <h1 id="offre_barre">Découvrez notre équipe</h1>
        <div
          style={{
            backgroundColor: '#fdfdfd',
            position: 'relative',
            zIndex: 2
          }}
        >
          <p>
            {
              'Notre joyeuse équipe, menée par Yohan, le fondateur, \
            ingénieur en Génie Industriel est composée de spécialistes en design d’applications web, en intelligence artificielle et en Big Data.'
            }
          </p>
        </div>
        <div style={{ maxWidth: 900, margin: '0 auto' }}>
          <Row type="flex" justify="center" style={{ width: '100%' }}>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">YOHAN</p>
                <i id="function">Directeur</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">LAURA</p>
                <i id="function">Responsable technique</i>
              </div>
            </Col> 
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">SARAH</p>
                <i id="function">Assistante de Direction</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">DARINE</p>
                <i id="function">Assistante RH</i>
              </div>
            </Col>
           
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">LISA</p>
                <i id="function">Responsable Commerciale</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">MAZIGH</p>
                <i id="function">DevOps</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">NOÉMIE</p>
                <i id="function">Web Designer</i>
              </div>
            </Col> 
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">ROMAIN</p>
                <i id="function">Lead Développeur</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">ANTOINE</p>
                <i id="function">Lead Développeur / R&D</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">MALIK</p>
                <i id="function">Lead Développeur</i>
              </div>
            </Col>
             <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">NICOLAS</p>
                <i id="function">Lead Développeur</i>
              </div>
            </Col> 
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">GUILLAUME</p>
                <i id="function">Développeur / R&D</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">AHMED</p>
                <i id="function">Testeur fonctionnel</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">QUENTIN</p>
                <i id="function">Développeur</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">DIMITRI</p>
                <i id="function">Développeur</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">MOANA</p>
                <i id="function">Développeur</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">MICHEL</p>
                <i id="function">Développeur</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">VINCENT</p>
                <i id="function">Développeur</i>
              </div>
            </Col>
            <Col xs={24} sm={8}>
              <div className="employe">
                <p id="name">SONIA</p>
                <i id="function">Développeuse</i>
              </div>
            </Col>    
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Equipes;
