import React from 'react';
import Equipes from './Equipes';
import Partenaires from './Partenaires';
import '../../CSS/equipespartenaires.css';

const EquipesPartenaires = props => {
  return (
    <div>
      <div className="equipes_partenaires">
        <Equipes />
        <Partenaires />
      </div>
    </div>
  );
};

export default EquipesPartenaires;
