import React, { createContext, useContext, useEffect } from 'react';
import { ConfigProvider, Empty } from 'antd';
import moment from 'moment';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { locales } from 'antd/dist/antd-with-locales';
import { useReducerWithSessionStorage } from '../utils';
import en_GB from '../locales/en_GB';
import fr_FR from '../locales/fr_FR';
import 'moment/locale/fr';

moment.locale('en');

const customizeRenderEmpty = () => <Empty />;

i18n.use(initReactI18next).init({
  resources: {
    en_GB,
    fr_FR
  },
  lng: 'fr_FR',
  fallbackLng: 'en_GB',
  interpolation: {
    escapeValue: false
  }
});

const LanguageContext = createContext({});

const localeReducer = (state, action) => {
  if (action.type === 'SET_LOCALE') {
    if (locales[action.locale]) {
      moment.locale(action.locale.split('_')[0]);
      i18n.changeLanguage(action.locale);
      return action.locale;
    }
  }
  return state;
};

export const LanguageContextProvider = ({ children }) => {
  const [locale, dispatch] = useReducerWithSessionStorage(
    'locale',
    localeReducer,
    'fr_FR'
  );

  useEffect(() => {
    dispatch({ type: 'SET_LOCALE', locale });
  }, [locale, dispatch]);

  return (
    <LanguageContext.Provider value={{ locale, dispatch }}>
      <ConfigProvider
        locale={locales[locale]}
        renderEmpty={customizeRenderEmpty}
      >
        {children}
      </ConfigProvider>
    </LanguageContext.Provider>
  );
};

export default () => useContext(LanguageContext);
