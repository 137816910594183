import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Logo from '../../assets/images/logo.png';
import '../../CSS/header.css';
import MobileMenu from './MobileMenu';
import { useEffect } from 'react';

const { Header } = Layout;

const TopHeader = () => {
  const [headerStyle, setHeaderStyle] = useState(false);
  const [onAccueil, setOnAccueil] = useState(true);
  const [onOffres, setOnOffres] = useState(false);
  const [onEquipes, setOnEquipes] = useState(false);
  const [onRecrutement, setOnRecrutement] = useState(false);
  const [onActualite, setOnActualite] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    switch (window.location.pathname) {
      case '/accueil':
        setOnAccueil(true);
        setOnOffres(false);
        setOnRecrutement(false);
        setOnEquipes(false);
        setOnActualite(false);
        break;
      case '/offres':
      case '/genie-logiciel':
      case '/conseil':
      case 'editeur-logiciel':
        setOnAccueil(false);
        setOnOffres(true);
        setOnRecrutement(false);
        setOnEquipes(false);
        setOnActualite(false);
        break;
      case '/equipes':
        setOnAccueil(false);
        setOnOffres(false);
        setOnRecrutement(false);
        setOnEquipes(true);
        setOnActualite(false);
        break;
      case '/recrutement':
        setOnAccueil(false);
        setOnOffres(false);
        setOnRecrutement(true);
        setOnEquipes(false);
        setOnActualite(false);
        break;
      case '/blog':
        setOnAccueil(false);
        setOnOffres(false);
        setOnRecrutement(false);
        setOnEquipes(false);
        setOnActualite(true);
        break;
    }
  });

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setHeaderStyle(true);
    } else {
      setHeaderStyle(false);
    }
  };

  const changeMenuItem = event => {
    switch (event.key) {
      case 'accueil':
        setOnAccueil(true);
        setOnOffres(false);
        setOnRecrutement(false);
        setOnEquipes(false);
        setOnActualite(false);
        break;
      case 'offres':
        setOnAccueil(false);
        setOnOffres(true);
        setOnRecrutement(false);
        setOnEquipes(false);
        setOnActualite(false);
        break;
      case 'equipesandpartenaires':
        setOnAccueil(false);
        setOnOffres(false);
        setOnRecrutement(false);
        setOnEquipes(true);
        setOnActualite(false);
        break;
      case 'recrutement':
        setOnAccueil(false);
        setOnOffres(false);
        setOnRecrutement(true);
        setOnEquipes(false);
        setOnActualite(false);
        break;
      case 'blog':
        setOnAccueil(false);
        setOnOffres(false);
        setOnRecrutement(false);
        setOnEquipes(false);
        setOnActualite(true);
        break;
      default:
        setOnAccueil(true);
        setOnOffres(false);
        setOnRecrutement(false);
        setOnEquipes(false);
        setOnActualite(false);
        break;
    }
  };

  return (
    <>
      <Layout className="layout">
        <Header className={headerStyle ? 'blueHeader' : 'transHeader'}>
          <Link to="/" onClick={changeMenuItem}>
            <img src={Logo} className="logo-header" alt="strateg.in" />
          </Link>
          {isMobile ? (
            <MobileMenu />
          ) : (
            <Menu
              style={{
                float: 'right',
                height: '60px',
                lineHeight: '50px',
                backgroundColor: 'transparent',
                marginTop: 5,
                fontSize: 16
              }}
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={['accueil']}
            >
              <Menu.Item
                key="accueil"
                className={onAccueil && 'menu-item-selected'}
                style={{ backgroundColor: 'transparent' }}
                onClick={changeMenuItem}
              >
                <Link to="/">Accueil</Link>
              </Menu.Item>
              <Menu.Item
                key="offres"
                className={onOffres && 'menu-item-selected'}
                style={{ backgroundColor: 'transparent' }}
                onClick={changeMenuItem}
              >
                <Link to="/offres">Offres</Link>
              </Menu.Item>
              <Menu.Item
                key="equipesandpartenaires"
                className={onEquipes && 'menu-item-selected'}
                style={{ backgroundColor: 'transparent' }}
                onClick={changeMenuItem}
              >
                <Link to="/equipes">Equipe & Partenaires</Link>
              </Menu.Item>
              <Menu.Item
                key="recrutement"
                className={onRecrutement && 'menu-item-selected'}
                style={{ backgroundColor: 'transparent' }}
                onClick={changeMenuItem}
              >
                <Link to="/recrutement">Recrutement</Link>
              </Menu.Item>
              <Menu.Item
                key="blog"
                className={onActualite && 'menu-item-selected'}
                style={{ backgroundColor: 'transparent' }}
                onClick={changeMenuItem}
              >
                <Link to="/blog">Actualités</Link>
              </Menu.Item>
              <Menu.Item
                key="contact"
                style={{ backgroundColor: 'transparent' }}
              >
                <a href="#contact-us">Contactez-nous</a>
              </Menu.Item>
            </Menu>
          )}
        </Header>
      </Layout>
    </>
  );
};
export default TopHeader;
