import React from 'react';
import { Row, Col } from 'antd';
import CardPartenaire from './CardPartenaires';
import ListePartenaires from './ListePartenaires';
import '../../CSS/equipespartenaires.css';

const partenaires = ListePartenaires()

const Partenaires = () => {
  return (
    <div className="partenaires" style={{position: 'relative', zIndex: 2, backgroundColor: '#fdfdfd'}}>
      <div style={{backgroundColor: '#fdfdfd', height: 1 }}></div>
      <h1 id='offre_barre'>Découvrez nos partenaires</h1>
      <div className="partenaires_liste">
      <Row gutter={[48, 48]} id="row-partenaires" type='flex' justify='center'>
        {partenaires.map(part => (
          <Col xs= {24} md={12} lg={8}>
            <a href={part.web} target="_blank">
          <CardPartenaire logo={part.logo} name={part.name} content={part.content} />
          </a>
          </Col>
      ))}
      </Row>
      </div>
    </div>
  );
};

export default Partenaires;