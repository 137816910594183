import React, { useState } from 'react';
import { Upload, message, Form, Icon } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const DragnDrop = ({ getFieldDecorator, props, cvpath }) => {
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Form.Item>
      {getFieldDecorator('dragger', {
        valuePropName: 'file',
        getValueFromEvent: cvpath,
      })(
        <Upload.Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">Cliquez ou glissez-déposez vos documents (cv, lettre de motivation, ...)</p>
        </Upload.Dragger>,
      )}
    </Form.Item>
  );
};
export default DragnDrop;
