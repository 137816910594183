import React from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import AProposWebSiteStrategin from '../../assets/images/apropos.webp';
import AProposWebSiteStrateginBis from '../../assets/images/safari/apropos.png';


const Apropos = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <div style={{ zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd' }}>
      <div style={{ backgroundColor: '#fdfdfd', height: 1 }}></div>
      <h1 id="offre_barre"> A propos </h1>
      <Row className="row-apropos">
        <Col lg={{ span: 13, offset: 2 }} style={{ textAlign: 'justify', paddingRight: 20, paddingLeft: 20 }}>
          <p>
            Aujourd’hui, 87% des PME/PMI françaises n'ont toujours pas franchi
            le cap de la transformation numérique. Pourtant, jusqu'à 75% des
            prospects recherchent, sur le web, des informations sur une
            entreprise et ses activités avant d'entamer la relation
            commerciale...
          </p>
          <br />
          <p>
            Pour la transformation numérique de votre entreprise, nous vous
            proposons la solution la plus adaptée : qu'il s'agisse de déployer
            un logiciel existant ou de développer un outil sur-mesure, nous
            menons le projet avec vous, de A à Z.
          </p>
          <br />
          <p>
            Issus du monde de l'ingénierie industrielle, nous voulons apporter
            à votre entreprise les outils digitaux qui vous permettront de donner
            de la voix au message que vous portez et qui représenteront surtout
            un gain de productivité décisif, par la rationalisation et
            l’automatisation de vos processus clés. Votre transition numérique
            sera également un vecteur d’innovation et de performance pour votre
            business.
          </p>
        </Col>
        {!isMobile && (<Col lg={{ span: 6, offset: 1 }} style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            className="img-apropos"
            src={AProposWebSiteStrategin}
            src={AProposWebSiteStrateginBis}
            alt="outils digitaux pme pmi"
          />
        </Col>)}
      </Row>
    </div>
  );
};
export default Apropos;
