import React, { useState, useEffect } from 'react';
import { Layout, Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import './BasicLayout.css';

const { Content, Footer, Sider } = Layout;

const BasicLayout = ({ children, path }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  const getAssetsName = async () => {
    try {
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  useEffect(() => {
    getAssetsName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCollapse = newCollapsed => {
    setCollapsed(newCollapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider
        className="container-sider"
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <Menu
          style={{ padding: '16px 0', background: '#0032A0' }}
          theme="dark"
          defaultSelectedKeys={[path]}
          activeKey={path}
          mode="inline"
        >
          <Menu.Item key="/">
            <NavLink to="/" className="nav-text">
              <Icon type="pie-chart" />
              <span>{t('dashboard.title')}</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <HeaderLayout collapsed={collapsed} collapseSider={setCollapsed} />
        <Content className="content">{children}</Content>
        <Footer className="footer" style={{ textAlign: 'center' }}>
          {t('global.title')} ©2019 powered by{' '}
          <a href="https://strateg.in">Strateg.in</a>
        </Footer>
      </Layout>
    </Layout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
};

export default BasicLayout;
