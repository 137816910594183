import React from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'antd';
import showdown from 'showdown';
import { useMediaQuery } from 'react-responsive';
import Bandeau from '../Bandeau/bandeau.jsx';
import facebookIcon from '../../assets/partageIcon/facebook.svg'
import twitterIcon from '../../assets/partageIcon/twitter.svg'
import linkedinIcon from '../../assets/partageIcon/linkedin.svg'


const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

const BlogPost = props => {
  const post = props.location.post
  const date = new Date(props.location.post.createdAt);
  const allposts = props.location.posts
  const indice = allposts.indexOf(post)
  const previouspost = allposts[indice - 1]
  const nextpost = allposts[indice + 1]
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })


  const converter1 = new showdown.Converter()
  const description = converter1.makeHtml(post.htmlBody);
  const image = baseUrl.concat('', post.image.url)

  const handleShare = (e) => {
    e.preventDefault()
    const href = e.target.getAttribute("href")
    const network = e.target.getAttribute("data-network")
    console.log(href, network)
    const networks = {
      facebook: { width: 600, height: 300 },
      twitter: { width: 600, height: 254 },
      linkedin: { width: 600, height: 473 }
    };
    const popup = function (network) {
      const options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,';
      const windowLeft = window.screenLeft || window.screenX;
      const windowTop = window.screenTop || window.screenY;
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const popupLeft = windowLeft + windowWidth / 2 - networks[network].width / 2;
      const popupTop = windowTop + windowHeight / 2 - networks[network].height / 2;
      window.open(href, '', options + 'height=' + networks[network].height + ',width=' + networks[network].width + 'top=' + popupTop + ', left=' + popupLeft);
    }
    popup(network)
  }
  const url = encodeURIComponent("https://strateg.in/blog")
  return (
    <>
      <div className="blogpost">
        {isMobile ? (<><Bandeau
          image={image}
          title=""
          text=""
        /><p style={{ position: 'absolute', top: 100, marginLeft: 30, marginRight: 30, color: "#fff", textAlign: 'center', fontSize: 20 }}>{post.title}</p></>)
          :
          (<Bandeau
            image={image}
            title={post.title}
            text=""
          />)
        }

        <div dangerouslySetInnerHTML={{ __html: description }} className="poststrapi" />
        <p style={{ textAlign: 'center' }}>par {post.author} - le {date.toLocaleDateString('fr-FR')}</p>
        <p style={{ textAlign: 'center' }}>Partagez cet article: </p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 50 }}>
        <img src={facebookIcon} alt="facebook" style={{ wisth: 50, height: 50, margin: 20, cursor: 'pointer' }} onClick={handleShare} href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} class="share facebook" data-network="facebook" />
        <img src={twitterIcon} alt="twitter" style={{ wisth: 50, height: 50, margin: 20, cursor: 'pointer' }} onClick={handleShare} href={`https://twitter.com/share?url=${url}`} class="share twitter" data-network="twitter" />
        <img src={linkedinIcon} alt="linkedin" style={{ wisth: 50, height: 50, margin: 20, cursor: 'pointer' }} onClick={handleShare} href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}`} class="share linkedin" data-network="linkedin" />
      </div>
      <Row className="linkposts">
        <Link to="/blog">
          <h2 id="link_content"></h2>
          <h1 id="link_title"> {"> Revenir aux actualitées <"}</h1>
        </Link>
      </Row>
    </>
  )
}

export default BlogPost;