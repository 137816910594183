import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ReactDOM from 'react-dom';
import Isotope from 'isotope-layout';
import { useMediaQuery } from 'react-responsive';
import { Button, Radio, Row, Menu, Dropdown, Icon } from 'antd';
import CardArticle from './CardArticle.jsx';
import '../../CSS/isolist.css';
require('isotope-cells-by-row');


const IsotopeBlog = ({posts}) => {
  // store the isotope object in one state
  const [isotope, setIsotope] = useState(null);
  // store the filter keyword in another state
  const [filterKey, setFilterKey] = useState("*");
  const [triDate, setTriDate] = useState(false);
  const [columnWidth, setColumnWidth ] = useState(300);

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const media1 = useMediaQuery({ query: '(max-width: 600px) and (min-width: 500px)' });
  const media2 = useMediaQuery({ query: '(max-width: 1010px) and (min-width: 820px)'})


  const menu = (
    <Menu style={{display: 'flex', flexDirection: 'column'}}>
      <Button onClick={() => setFilterKey("*")} value='all'>Tous</Button>
      <Button onClick={()=> setTriDate(true)} value='anciens'>+Anciens</Button>
      <Button onClick={()=> setTriDate(false)} value='recents'>+ Récents</Button>
      <Button onClick={() => setFilterKey("web")} value='web'>Web</Button>
      <Button onClick={() => setFilterKey("ia")} value='ia'>I.A</Button>
      <Button onClick={() => setFilterKey("bigdata")} value='bigdata'>Big Data</Button>
      <Button onClick={() => setFilterKey("embarque")} value='embarque'>Embarqué</Button>
      <Button onClick={() => setFilterKey("societee")} value='societee'>Société</Button>
      <Button onClick={() => setFilterKey("marketing")} value='marketing'>Marketing</Button>
    </Menu>
  );

 
  // initialize an Isotope object with configs
  useEffect(() => {
    if(media2) {setColumnWidth(400)};
  }, []);

  useEffect(() =>{
    setIsotope(
      new Isotope(".filter-container", {
        itemSelector: ".filter-item",
        layoutMode: 'cellsByRow',
        cellsByRow: {
          columnWidth: columnWidth,
          rowHeight: 450
        },
        sortBy: 'number'
      })
    );

  },[columnWidth])

  // handling filter key change
  useEffect(
    () => {
      if (isotope) {
        filterKey === "*"
          ? isotope.arrange({ filter: `*` })
          : isotope.arrange({ filter: `.${filterKey}` });
        
        triDate === true
          ? isotope.arrange({sortAscending: true})
          : isotope.arrange({sortAscending: false});
      }
    },
    [isotope, filterKey, triDate]
  );

  console.log(columnWidth);
  return (

    <>
        {isMobile ? (
        <Dropdown overlay={menu} trigger={['click']}>
          <Row style={{display: 'flex', justifyContent: 'center', marginTop: 23}} className='dropfiltres'>
          <Button>Filtres <Icon type="down" /></Button>
          </Row>
        </Dropdown>
        )
        :
        (
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: 50, marginBottom: 30}}>
        <Radio.Group style={{margin: 'auto'}}>
          <Radio.Button onClick={() => setFilterKey("*")} value='all'>Tous</Radio.Button>
          <Radio.Button onClick={()=> setTriDate(true)} value='anciens'>+Anciens</Radio.Button>
          <Radio.Button onClick={()=> setTriDate(false)} value='recents'>+ Récents</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("web")} value='web'>Web</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("ia")} value='ia'>I.A</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("bigdata")} value='bigdata'>Big Data</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("embarque")} value='embarque'>Embarqué</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("societee")} value='societee'>Société</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("marketing")} value='marketing'>Marketing</Radio.Button>
        </Radio.Group>
        </Row>
        )}
            <ul className="filter-container" >
              {posts.map(post => { 
              return (
                <div className={`filter-item ${post.tags.replace(",", "")}`}>
                  <span><p className="number" style={{visibility: 'hidden'}}>{posts.indexOf(post)}</p>
                  <Link to={{
                pathname:  `/blog/${post.id}`,
                post:  post,
                posts: posts
              }}>
                    <CardArticle article={post} />
                  </Link>
                </span>
              </div>
            )})}
          </ul>
    </>
  );
};

export default IsotopeBlog;