import React from 'react';
import NosOffres from './NosOffres';
import Logiciels from './Logiciels';
import Offre11 from '../../assets/images/Offre1.webp';
import Offre22 from '../../assets/images/Offre2.webp';
import Offre3 from '../../assets/images/Offre3.webp';
import Offre11Bis from '../../assets/images/safari/Offre1.png';
import Offre22Bis from '../../assets/images/safari/Offre2.png';
import Offre3Bis from '../../assets/images/safari/Offre3.png';
import BandeauOffres from '../Bandeau/bandeauOffres';

const EditeurLogiciel = () => {
    return (
        <div>
            <BandeauOffres
                image={navigator.userAgent.includes('Safari') ? Offre3Bis : Offre3}
                title="Éditeur de logiciel"
                text="Attentive aux besoins du marché, l'équipe de Strateg.in met toute sa créativité au service de votre entreprise en vous
                proposant des solutions logicielles innovantes et clé-en-main, vous permettant de prendre un temps d'avance sur vos
                concurrents."
            />
            <div style={{ zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd' }}>
                <Logiciels />
                <NosOffres url1="/conseil" url2="/genie-logiciel" img1={navigator.userAgent.includes('Safari') ? Offre11Bis : Offre11} img2={navigator.userAgent.includes('Safari') ? Offre22Bis : Offre22} name1="Conseil & formation" name2="Génie logiciel & développement sur mesure" />
            </div>
        </div>
    );
};
export default EditeurLogiciel;