import React, { useState } from 'react';
import { Button, Col, Row, Input, Tooltip, Menu, Radio } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import '../../CSS/recrutement.css';

const { Search } = Input;


const plusrecent = (
  <div>
    <p>voir les offres plus récentes</p>
  </div>
)
const plusancien = (
  <div>
    <p>voir les offres plus ancinennes</p>
  </div>
)

const Filtre = ({
  offres,
  filterSearch,
  sortDate,
  filterTags
}) => {
  const tagsList = [];

  offres.map(offre => {
    if (offre.tags) {
      tagsList.push(offre.tags.split(','));
    }
  });

  const tags = tagsList.flat().filter(tag => tag.length < 20)
  const allTags = Array.from(new Set(tags))

  const tagsMenu = (
    <Menu onClick={filterTags}>
      {allTags.map(tag => (
        <Menu.Item key={tag}>{tag}</Menu.Item>
      ))}
    </Menu>
  );

  // const datesMenu = (
  //   <Menu onClick={sortDate}>
  //     <Menu.Item key="1">plus récents</Menu.Item>
  //     <Menu.Item key="2">plus anciens</Menu.Item>
  //   </Menu>
  // );

  return (
    <div className="filtres-emplois">
      <Row>
      <Col xs={24} md={16} lg={12} style={{display: 'flex'}}>
      <Search placeholder="Search" onSearch={filterSearch} enterButton />
      {/* <Tooltip title="+ récents">
      <Button onClick={sortDate} value='1' type="primary"><DownOutlined /></Button>
      </Tooltip>
      <Tooltip title="+ anciens">
      <Button onClick={sortDate} value='2' type="primary"><UpOutlined /></Button>
      </Tooltip> */}
        </Col>
        {/* <Col xs={{span:8, offset:4}}  md={{span:1, offset: 0}}>
            <Button onClick={sortDate} value='1' type="primary"><DownOutlined /></Button>
        </Col>
        <Col xs={{span:8, offset:0}}  md={{span:1, offset: 0}}>
        <Button onClick={sortDate} value='2' type="primary"><UpOutlined /></Button>
        </Col> */}
      </Row>
      <Row>
      <div style={{marginTop: 10}}>
      <Radio.Group defaultValue="3" buttonStyle="solid">
      <Radio.Button onClick={sortDate} value="3">Tous</Radio.Button>
      <Radio.Button onClick={sortDate} value='1'>+ récents</Radio.Button>
      <Radio.Button onClick={sortDate} value='2'>+ anciens</Radio.Button>
      <Radio.Button onClick={filterTags} value="cdi">CDI</Radio.Button>
      <Radio.Button onClick={filterTags} value="stage">Stage</Radio.Button>
      <Radio.Button onClick={filterTags} value="web">Web</Radio.Button>
      <Radio.Button onClick={filterTags} value="embarque">Embarqué</Radio.Button>
      <Radio.Button onClick={filterTags} value="python">Python</Radio.Button>
      <Radio.Button onClick={filterTags} value="marketing">Marketing</Radio.Button>
      <Radio.Button onClick={filterTags} value="chef">Chef de projet</Radio.Button>
      <Radio.Button onClick={filterTags} value="fullstack">Fullstack</Radio.Button>
    </Radio.Group>
    </div>
      </Row>
    </div>
  );
};
export default Filtre;
