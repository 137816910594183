import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Input,
  Alert,
  Typography,
  Progress
} from 'antd';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
import Recaptcha from 'react-recaptcha';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Rectangle63 from '../../assets/images/ux-indonesia-qC2n6RQU4Vw-unsplash.webp';
import Rectangle63Bis from '../../assets/images/safari/ux-indonesia-qC2n6RQU4Vw-unsplash.jpg';
import QualiopiCertificate from '../../assets/training/Certificat Qualiopi.pdf';
import QualiopiLogo from '../../assets/images/QualiopiLogo.jpg';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const { Text } = Typography;

const Collaboraters = () => {
  const isMobileOrTab = useMediaQuery({ query: '(max-width: 900px' });
  const confirm = useRef();
  const [visible, setvisible] = useState(false);
  const [email, setEmail] = useState('');
  const [captchaValidate, setCaptchaValidate] = useState(true);
  const [recaptchaWidget, setRecaptchaWidget] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleChange = e => {
    setEmail(e.target.value);
  };
  const handleModal = () => {
    if (!captchaValidate) {
      setRecaptchaWidget(true);
    } else {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        setvisible(false);
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_SERVER}/catalogue`,
          data: {
            email: email
          }
        })
          .then(res => {
            setIsSuccess(true);
            setEmail('');
          })
          .catch(function(err) {
            setIsError(true);
            setEmail('');
          });
      } else {
        alert('Merci de saisir un adresse email valide !');
      }
    }
  };
  const verifyCaptcha = response => {
    if (response) {
      setCaptchaValidate(true);
    }
  };
  return (
    <div style={{ backgroundColor: '#f3f3f3' }}>
      <Row>
        <Col lg={{ span: 13, offset: 1 }} className="res-col2">
          <h1>Former vos collaborateurs</h1>
          <p>
            Dans un contexte où le numérique est devenu indispensable dans la
            gestion quotidienne des entreprises, nous formons vos collaborateurs
            aux outils commerciaux : depuis la découverte des bases de
            l'informatique jusqu'à l'appréhension de logiciels complexes
            développés sur-mesure. Nous organisons des sessions de formation
            individuelles ou collectives, dans nos locaux ou ceux de votre
            entreprise :
          </p>
          <p>
            - Formation des dirigeants aux outils de prise de décision, de
            management et aux outils commerciaux ;
            <br />
            - Formation des employés aux outils numériques ;
            <br />- Formation des utilisateurs aux applications une fois les
            programmes déployés.
          </p>
          <Row>
            <Col xxl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
              <FontAwesomeIcon icon={faInfoCircle} style={{ margin: '0px' }} />
              &nbsp; Nous sommes agréés &nbsp;
              <a href="https://travail-emploi.gouv.fr/formation-professionnelle/acteurs-cadre-et-qualite-de-la-formation-professionnelle/article/qualiopi-marque-de-certification-qualite-des-prestataires-de-formation">
                <img src={QualiopiLogo} alt="qualiopi" style={{ width: 160 }} />{' '}
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            </Col>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              xxl={{ span: 8 }}
              lg={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <p style={{ fontSize: 14 }}>Indicateurs de résultats 2022:</p>
              <p style={{ fontSize: 14 }}>Nombre de stagiaires : 4</p>
              <p style={{ fontSize: 14 }}>Taux de satisfaction</p>
              <div style={{ marginBottom: 25 }}>
                <Progress
                  strokeLinecap="square"
                  type="circle"
                  percent={98}
                  width={60}
                  strokeColor={'#063aa9'}
                  format={percent => `${percent} %`}
                />
              </div>
            </Col>

            <Col
              style={{
                display: 'flex',
                justifyContent: 'end'
              }}
              xxl={{ span: 8 }}
              lg={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <p>
                <a
                  href={QualiopiCertificate}
                  target="_blank"
                  download="Certificat Qualiopi.pdf"
                >
                  <Button
                    type="primary"
                    style={{ fontSize: 14, marginBottom: 20 }}
                  >
                    Télécharger certificat Qualiopi
                  </Button>
                </a>
              </p>
            </Col>
          </Row>
          <p>
            {/*<Button type="primary" onClick={() => setvisible(true)} style={{fontSize: 14}}>Demander le catalogue des formations</Button>
            <Modal
              title="Demander le catalogue des informations"
              okText="Envoyer ma demande"
              centered
              keyboard
              visible={visible}
              onOk={handleModal}
              onCancel={() => setvisible(false)}
            >
              <Input placeholder="Email" onChange={handleChange} value={email} />
               {recaptchaWidget && (<Recaptcha
                sitekey="6LezvqUZAAAAABQoLymElOwR4V9rI_N67_Kzh2Mn"
                render="explicit"
                onloadCallback={() => { console.log('ReCapthca chargé avec succés !') }}
                verifyCallback={verifyCaptcha}
              />)}
            </Modal>*/}
          </p>

          <Row>
            {isSuccess && (
              <Alert
                message="Demande envoyée !"
                description="Nous vous remercions, vous recevrez notre catalogue sous peu. "
                type="success"
                banner="true"
                closable="true"
                showIcon
                ref={confirm}
              />
            )}
            {isError && (
              <Alert
                message="Une erreur est survenue !"
                description="Nous vous remercions de réessayer ou de nous contacter si cela se reproduit. "
                type="error"
                banner="true"
                closable="true"
                showIcon
                ref={confirm}
              />
            )}
          </Row>
        </Col>

        <Col lg={{ span: 9, offset: 1 }}>
          <img
            src={Rectangle63}
            src={Rectangle63Bis}
            alt="outils commerciaux management programmes deployes"
            className="formation-images"
          />
        </Col>
      </Row>
    </div>
  );
};
export default Collaboraters;
