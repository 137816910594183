import React from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import Operer1 from '../../assets/images/laptop-3174729_1920.webp';
import '../../CSS/offrespage.css';
import '../../CSS/conseil.css';


const TransNumMobile = () =>{
    
    return(
        <div style={{backgroundColor: '#e7e7e7'}}>
            <Row >
                <img src={Operer1} alt="transformation numérique des entreprises" style={{width: '100%'}}/> 
                
                <Col className="res-col" span={24} >
                        <h1>Opérer la transformation numérique</h1>
                        <p>
                            Nous vous accompagnons dans la construction de l'ensemble des outils et méthodes
                            indispensables à une accélération digitale réussie pour votre entreprise.
                        </p>
                        <br/>
                        <p>
                            Nous réalisons tout d'abord un audit afin d'identifier vos processus métier devant être
                            digitalisés. Nous établissons ensuite le cahier des charges des outils répondant à vos besoins
                            pour définir la solution la mieux adaptée : logiciel existant sur le marché ou à développer
                            sur-mesure.
                        </p>
                        <br/>
                        <p>
                            Enfin,
                            nous mettons en oeuvre notre méthodologie Projet, incluant la conduite du changement
                            auprès des parties prenantes et utilisateurs, ainsi que leur formation.
                        </p>
                </Col>
            </Row>
        </div>
    );
}
export default TransNumMobile;