import React from 'react';
import { Row, Col } from 'antd';
import Group58 from '../../assets/images/Group58.webp';
import Group59 from '../../assets/images/Group59.webp';
import Group58Bis from '../../assets/images/safari/Group58.png';
import Group59Bis from '../../assets/images/safari/Group59.png';

import '../../CSS/logiciels.css';

const Logiciels = () => {
    return (
        <Row style={{ width: '100%', marginBottom: '65px', marginTop: '65px' }}>
            <Col lg={{ span: 12 }} xs={24}>
                <a href="https://findee.io/"><img src={navigator.userAgent.includes('Safari') ? Group58Bis : Group58} alt="findee" width="600px" className="logiciel-img" /></a>
                <div className="text-bloc2">
                    <h1>Atteignez de nouveaux sommets grâce
                    à l'Intelligence Artificielle
                    </h1>
                    <p>
                        Nous avons créé le logiciel Findee : l'application SaaS qui utilise l'intelligence
                        artificielle alliée au BigData pour vous permettre de trouver vos prochains
                        clients B2B.
                    </p>
                    <p>
                        Il simplifie la prospection de vos commerciaux et vous permet, grâce au Machine
                        Learning, d'identifier les meilleurs prospects.
                    </p>
                </div>
            </Col>
            <Col lg={{ span: 12 }} xs={24} >
                <a href="http://boxtolead.com/"><img src={navigator.userAgent.includes('Safari') ? Group59Bis : Group59} alt="box to lead" width="600px" className="logiciel-img2" /></a>
                <div className="text-bloc2">
                    <h1>Boostez la qualification de vos
                    prospects lors de vos évènements
                    </h1>
                    <p>
                        Nous avons développé, au format mobile et tablette, une application qui
                        enregistre en temps réel les coordonnées de vos prospects et maintient le
                        contact grâce à un système d'emailing.
                    </p>
                    <p>
                        Toute l'application est entièrement personnalisable à l'image de votre entreprise.
                    </p>
                </div>
            </Col>
        </Row>
    );
}
export default Logiciels;
