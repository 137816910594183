import React from 'react';
import { Card } from 'antd';
import '../../CSS/blog.css';
import showdown from 'showdown';
const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

const Article = props => {
  const { article } = props;
  const date = new Date(article.createdAt);

  const converter = new showdown.Converter()
  const desc = converter.makeHtml(article.htmlBody);
  const minDesc = desc.substring(0, 160);
  const minShortDesc = article.shortdesc.substring(0,120);
  const cardDesc = minShortDesc + ' ...';

  return (
    <Card
        className='dispo'
        style={{ width: 270, height: 410, borderRadius: 10 }}>
          <div style={{height: 180, width: '100%', padding: 10, backgroundImage: `url(${baseUrl.concat('', article.image.url)})`, backgroundSize: 'cover'}}>
          </div>
          
          <div className="poste">
            <p id="poste-date">publié le {date.toLocaleDateString('fr-FR')}</p>
        </div>
        <p id="poste-title" style={{fontSize: 15, marginBottom: 0}}>{article.title} </p>
        <p style={{textAlign: 'justify', padding: 10, fontSize: 13}}>{cardDesc}</p>
      </Card>
  );
};
export default Article;
