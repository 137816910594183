import React from 'react';
import { Row, Col, Timeline } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  
  faChalkboardTeacher, 
  faProjectDiagram, 
  faFileSignature, 
  faDesktop, 
  faRocket
} from '@fortawesome/free-solid-svg-icons';
import '../../CSS/methodologie.css';

const Methodologie = () => {
  return (
    <Row style={{ paddingTop: '30px' ,paddingBottom:'30px', textAlign: 'justify'}}>
      <Col span={19} offset={2}>
        <Timeline>
          <Timeline.Item dot={<FontAwesomeIcon icon={faChalkboardTeacher} style={{fontSize: '35px',color:'#063AA9'}}/>}>
            Après un audit complet de vos processus internes exisants, nous établissons des stratégies adaptées à votre
            entreprise et fondées sur des Business Models innovants pour implémenter une solution personnalisée.
          </Timeline.Item>
          <Timeline.Item dot={<FontAwesomeIcon icon={faProjectDiagram} style={{fontSize: '35px',color:'#063AA9'}}/>}>
            Une fois l'audit réalisé, nous construisons l'architecture générale de votre site internet ou de votre outil web. Nous réalisons le
            zoning pour chacune des pages de l'application : il s'agit de la disposition générale de tous les élements constituant la
            page.
          </Timeline.Item>
          <Timeline.Item dot={<FontAwesomeIcon icon={faFileSignature} style={{fontSize: '35px',color:'#063AA9'}}/>}>
            A partir de l'architecture validée par nos chefs de projet, nous réalisons la première page de votre projet en format Web, consultable par vos
            soins sur un serveur test : votre projet commence à prendre vie.
          </Timeline.Item>
          <Timeline.Item dot={<FontAwesomeIcon icon={faDesktop} style={{fontSize: '35px',color:'#063AA9'}}/> }>
            Nous développons ensuite, une à une, les différentes pages de votre projet, puis les rendons fonctionnelles. Chaque
            avancée est marquée par un point d'étape afin de garantir la progression du développement de votre application.
          </Timeline.Item>
          <Timeline.Item dot={<FontAwesomeIcon icon={faRocket} style={{fontSize: '35px',color:'#063AA9'}}/>}>
            Lorsque le projet est finalisé, nous le déployons sur vos serveurs puis, après une phase de test, nous formons vos
            collaborateurs afin qu'ils soient immédiatement opérationnels sur l'utilisation de ce nouvel outil.
          </Timeline.Item>
        </Timeline>
      </Col>
    </Row>
  );
};

export default Methodologie;
