import React from 'react';
import { Row, Col} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import '../../CSS/offrespage.css';
const NosOffres = (props) =>{
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const isiPade = useMediaQuery({ query: '(max-width: 900px) and (min-width: 500px)'});
    return(
      <div>
        {isMobile ? 
          <div>
            <Row style={{ paddingBottom: '10px', paddingTop: '10px'}}>
              <h1 style={{color: '#063AA9', fontSize: '20px', textAlign: 'center',fontWeight : 'bold'}}> Découvrez nos offres</h1>
              <Col span={24} >
                <Link to={props.url1}>
                  <img
                    src={props.img1}
                    alt="conseil et formation"
                    width="100%"
                    style={{height:200, objectFit: 'cover'}}
                  />
                  <div className="overlay-offre2">
                    <div>{props.name1}</div>
                  </div>
                </Link>
              </Col>
              <Col span={24}>
                <Link to={props.url2} >
                  <img
                    src={props.img2}
                    alt="editeur de logiciel"
                    width="100%"
                    style={{height:200, objectFit: 'cover'}}
                  />
                  <div className="overlay-offre2">
                    <div>{props.name2}</div>
                  </div>
                </Link>
              </Col>
            </Row>
          </div>
         /* : isiPade ?
            <div>
              <Row type="flex" style={{ fontSize: '16px', paddingBlock: '20px', alignItems: 'center', paddingLeft: '27px'}}>
              <Col span={4}>
                <Link to={props.url1} >
                  <img
                    src={props.img1}
                    alt="conseil et formation"
                    width="100px"
                    height="auto"
                  />
                </Link>
              </Col>
              <Col span={16}>
                <h1  style={{color: 'white', paddingLeft: '62px'}}>
                  Découvrez nos offres
                </h1>
              </Col>
              <Col span={4}>
                <Link to={props.url2} >
                  <img
                    src={props.img2}
                    alt="editeur de logiciel"
                    width="100px"
                    height="auto"
                  />
                </Link>
              </Col>
              </Row>
            </div>*/
            :
            <Row className = "nosoffres-row">
              <Col span={8}  className="nosoffres">
                <Link to={props.url1} className="nosoffres-link">
                  <img
                    src={props.img1}
                    alt="conseil et formation"
                  />
                  <div className="overlay-offre2">
                    <div>{props.name1}</div>
                  </div>
                </Link>

              </Col>
              <Col span={8} className="nosoffres-title">
                <h1>
                  Découvrez nos offres
                </h1>
              </Col>
              <Col span={8}  className="nosoffres">
                <Link to={props.url2} className="nosoffres-link">
                  <img
                    src={props.img2}
                    alt="editeur de logiciel"
                  />
                  <div className="overlay-offre2">
                    <div>{props.name2}</div>
                  </div>
                </Link>
              </Col>
            </Row> 
          }
    </div>
    );
};
export default NosOffres;