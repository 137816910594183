import React from 'react';
import ParticlesBackground from '../../components/Accueil/Particles';
import Apropos from '../../components/Accueil/Apropos';
import Offres from '../../components/Accueil/Offres';
import Realisations from '../../components/Accueil/Realisations';
import '../../CSS/home.css'

const Home = () => {
  return (
    <div className="home">
      <ParticlesBackground />
      <Apropos />
      <Offres />
      <Realisations />
    </div>
  );
};

export default Home;
