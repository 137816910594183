import BoosterActivLogo from '../../assets/images/logopartenaires/BoosterActivLogo.png'
import LeaseticLogo from '../../assets/images/logopartenaires/LeaseticLogo.png'
import OpenIndusLogo from '../../assets/images/logopartenaires/OpenIndusLogo.png'
import RBMGLogo from '../../assets/images/logopartenaires/RBMGLogo.png'
import TeepyLogo from '../../assets/images/logopartenaires/TeepyLogo.png'



const partenaires = [
    {
      "logo": TeepyLogo,
      "web": "https://www.teepy-entrepreneur.com",
      "name": "Teepy",
      "content": "TeePy Entrepreneurs est un réseau social collaboratif dédié aux artisans, commerçants et TPE. Tous les chefs d’entreprises de moins de 15 salariés et entrepreneurs sont invités à partager leurs actualités professionnelles, leurs bons plans et leurs nouveautés. Ensemble de produits ou services pour développer votre activité."
    },
    {
      "logo": LeaseticLogo,
      "web": "http://leasetic.com/",
      "name": "Leasétic",
      "content": "Né de 25 ans d'expérience sur le marché de la location et de la gestion d'actifs informatiques auprés des Grands Comptes, Leasetic apporte une nouvelle approche du financement d'un projet informatique ou industriel en fournissant les outils et les méthodes pour obtenir des contrats à haute performance."
    },
    {
      "logo": OpenIndusLogo,
      "web": "https://openindus.com/",
      "name": "Open Indus",
      "content": "OpenIndus offre des solutions innovantes dans le domaine de l'automatisme embarqué, compatibles avec les plus grandes plateformes open sources. De la conception à la commercialisation OpenIndus accompagne l'automatisation de vos produits intelligents grâce à des solutions standards et sur-mesure. "
    },
    {
      "logo": RBMGLogo,
      "web": "https://rbmg.fr",
      "name": "RBMG",
      "content": "Solide réseau d’entrepreneurs qui accompagne les dirigeants de petites entreprises dans la création et le développement de leurs projets.  RBMG intervient au sein des TPE/PME à temps partagé selon leurs besoins. Solutions d'outils créés par des chefs d’entreprises pour les créateurs, Start-up, dirigeants..."
    },
    {
      "logo": BoosterActivLogo,
      "web": "https://www.booster-activ.fr",
      "name": "Booster Activ",
      "content": "Le réseau Booster Activ est un réseau international d’experts spécialisés en stratégie de développement commercial et marketing. Fort de son expérience, il intervient en B2B afin de vous permettre de développer votre performance et celle de vos collaborateurs par le biais de l’externalisation des ressources commerciales."
    },
]

const ListePartenaires = () => {
  return partenaires
}

export default ListePartenaires;
