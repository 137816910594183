import React, { useState, useEffect } from 'react';
import axios from 'axios';
import orderBy from 'lodash/orderBy';
import Spinner from 'react-spinner-material';
import '../../CSS/blog.css';
import image from '../../assets/images/actualite2.webp';
import imageBis from '../../assets/images/safari/actualite2.jpg';
import IsotopeBlog from './IsotopeBlog.jsx';
import Bandeau from '../Bandeau/bandeau.jsx';

const endpoint_posts = `${process.env.REACT_APP_STRAPI_BASE_URL}/articles-blogs`
const mongodb_atlas = `${process.env.REACT_APP_SERVER}/api/blog`

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [allposts, setAllPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(40);
  const [listPosts, setListPosts] = useState([])

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const result = await axios.get(endpoint_posts);
      setPosts(result.data.reverse());
      setAllPosts(result.data);
      setLoading(false);
      const listInter = []
      result.data.map(post => {
        listInter.push({
          id: result.data.indexOf(post).toString(),
          post: post,
          filter: post.tags.split(',')
        })
      })
      setListPosts(listInter)
    };
    fetchPosts();
  }, []);

  const filterSearch = word => {
    setPosts(allposts.filter(post => post.htmlBody.indexOf(word) !== -1));
  };

  const filterTags = tag => {
    setPosts(allposts.filter(post => post.tags.indexOf(tag.key) !== -1));
  };

  const filterAuthors = auteur => {
    setPosts(allposts.filter(post => post.author !== auteur.key));
  };

  const sortDate = event => {
    if (event.target.value === '1') {
      const plus_recents = orderBy(allposts, ['created_at'], ['desc']);
      setPosts(plus_recents);
    } else {
      const plus_anciens = orderBy(allposts, ['created_at'], ['asc']);
      setPosts(plus_anciens);
    }
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = numberPage => setCurrentPage(numberPage);
  const browser = navigator.userAgent


  return (
    <div className="blog">
      <Bandeau
        image={navigator.userAgent.includes('Safari') ? imageBis : image}
        title="Actualités"
        text="Retrouver toutes nos actualités et les tendances du secteur..."
      />
      {loading && (<div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
        <Spinner size={120} spinnerColor={"#111"} spinnerWidth={2} visible={true} />
      </div>)}
      {allposts.length !== 0 && <IsotopeBlog posts={allposts} />}
    </div>
  );
};
export default Blog;
