import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Button, Tag } from 'antd';
import axios from 'axios';
import IsoTopeGrid, { Card } from "react-isotope";
import Spinner from 'react-spinner-material';
import CardOffre from './CardOffre';
import orderBy from 'lodash/orderBy';
import Paginations from './Pagination';
import Filtres from './Filtres';
import image from '../../assets/images/espace_emploi1.1.webp';
import imageBis from '../../assets/images/safari/espace_emploi1.1.png';

import '../../CSS/recrutement.css';
import IsoList from '../Isotope/IsoList';
import JobFilter from './filterjob';
import Bandeau from '../Bandeau/bandeau.jsx';

const endpoint_offres = `${process.env.REACT_APP_STRAPI_BASE_URL}/offre-emplois`
const mongodb_atlas = `${process.env.REACT_APP_SERVER}/api/jobs`

const Offres = () => {
  const [offres, setOffres] = useState([])
  const [allOffres, setAllOffres] = useState([])
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [offresPerPage, setOffresPerpage] = useState(40);
  const [listOffres, setListOffres] = useState([])

  useEffect(() => {
    const fetchOffres = async () => {
      setLoading(true);
      const result = await axios.get(endpoint_offres);
      setOffres(result.data);
      setAllOffres(result.data);
      setLoading(false);
      const listInter = []
      result.data.map(offre => {
        listInter.push({
          id: result.data.indexOf(offre).toString(),
          offre: offre,
          filter: offre.tags.split(',')
        })
      })
      setListOffres(listInter)
    };
    fetchOffres();
  }, []);


  const filterSearch = word => {
    setOffres(allOffres.filter(offre => offre.htmlBody.indexOf(word) !== -1));
  };

  const filterTags = tag => {
    setOffres(allOffres.filter(offre => offre.tags && offre.tags.indexOf(tag.value) !== -1));
  };


  const sortDate = event => {
    if (event.target.value === '1') {
      const plus_recents = orderBy(allOffres, ['created_at'], ['desc']);
      setOffres(plus_recents);
    } else {
      const plus_anciens = orderBy(allOffres, ['created_at'], ['asc']);
      setOffres(plus_anciens);
    }
  };

  const indexOfLastOffre = currentPage * offresPerPage;
  const indexOfFirstOffre = indexOfLastOffre - offresPerPage;
  const currentOffres = offres.slice(indexOfFirstOffre, indexOfLastOffre);

  const paginate = numberPage => setCurrentPage(numberPage);

  return (
    <div className="offres">
      <Bandeau
        image={navigator.userAgent.includes('Safari') ? imageBis : image}
        title="Espace emploi"
        text="Notre équipe s'agrandit régulièrement et est en constante recherche de nouveaux talents ..."
      />
      {loading && (<div style={{ display: 'flex', justifyContent: 'center', marginTop: 50 }}>
        <Spinner size={120} spinnerColor={"#111"} spinnerWidth={2} visible={true} />
      </div>)}
      {/* <div className="title_offres_emplois">
        <div className="img-emploi" />
      <div className="title_offres">
      <h1>Espace emploi</h1>
      <h3>Notre équipe s'agrandit régulièrement et est en constante recherche de nouveaux talents ...</h3>
      </div>
      </div> */}
      {/* <div style={{backgroundColor: '#fdfdfd', position: 'relative', zIndex: 3, paddingTop: 20}}> */}
      {/* <Filtres
        offres={allOffres}
        filterSearch={filterSearch}
        sortDate={sortDate}
        filterTags={filterTags}
      /> */}
      {/* <IsoList offres={listOffres} /> */}
      {allOffres.length !== 0 && <JobFilter offres={allOffres} />}

      {/* {loading && (<Row justify="center" className="loading">
      <Button type="primary" loading>
        Loading
      </Button>
    </Row>)} */}
      {/* {console.log(listOffres)}
    <IsoList offres={listOffres} /> */}
      {/* <div className="liste_offres">
      {currentOffres.map(offre => (
        <Link to={{
          pathname:  `/recrutement/offres/${offre.id}`,
          state: {offre}
        }}>
        <CardOffre offre={offre}/>
        </Link>
      ))}
      </div> */}
      {/* <Paginations
        className="pagination"
        offresPerPage={offresPerPage}
        totalOffres={offres.length}
        paginate={paginate}
      /> */}
      {/* <div>
        <Link to='/recrutement/candidature'>
      <h2 id="link_content">Aucun poste ne vous correspond ? Envoyez-nous votre candidature !</h2>
      <h1 id="link_title"> {"> Candidature spontanée <"}</h1>
      </Link>
        </div>
      </div> */}
    </div>
  )
}
export default Offres;
