import React, { useState, useRef } from 'react';
import { Form, Input, Button, Row, Col, Alert } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import axios from 'axios';
import Recaptcha from 'react-recaptcha';
import '../../CSS/contact.css';

const { TextArea } = Input;

const FormContact = props => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [captchaValidate, setCaptchaValidate] = useState(true);
  const [recaptchaWidget, setRecaptchaWidget] = useState(false);
  const confirm = useRef();

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (!captchaValidate) {
          setRecaptchaWidget(true);
        } else {
          setRecaptchaWidget(false);
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_SERVER}/contact`,
            data: {
              nom: values.nom,
              prenom: values.prenom,
              email: values.email,
              tel: values.tel,
              message: values.message
            }
          })
            .then(() => {
              setIsSuccess(true);
              props.form.resetFields();
            })
            .catch(function(erreur) {
              setIsError(true);
            });
          confirmFocus();
        }
      }
    });
  };
  const confirmFocus = () => {
    confirm.current.focus();
  };
  const { getFieldDecorator } = props.form;

  const verifyCaptcha = response => {
    if (response) {
      setCaptchaValidate(true);
    }
  };

  return (
    <Form className="form-contact" onSubmit={handleSubmit}>
      <Row gutter={18}>
        <Col xs={24} md={12}>
          <Form.Item>
            {getFieldDecorator('nom', {
              rules: [{ required: true, message: 'Entrer votre nom svp!' }]
            })(
              <Input
                placeholder="Nom"
                size="large"
                className="input-contact"
                defaultValue=""
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item>
            {getFieldDecorator('prenom', {
              rules: [{ required: true, message: 'Entrer votre prénom svp!' }]
            })(
              <Input
                placeholder="Prénom"
                size="large"
                className="input-contact"
                defaultValue=""
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col xs={24} md={12}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                { required: true, message: 'Entrer votre email svp!' },
                {
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "votre email n'est pas valide!"
                }
              ]
            })(
              <Input
                placeholder="Email"
                size="large"
                className="input-contact"
                defaultValue=""
              />
            )}
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item>
            {getFieldDecorator('tel', {
              rules: [
                {
                  required: true,
                  message: 'Entrer votre numéro de téléphone svp!'
                },
                {
                  pattern: /^(01|02|03|04|05|06|07|08|09)[0-9]{8}$/i,
                  message: "le numéro n'est pas valide"
                }
              ]
            })(
              <Input
                placeholder="Téléphone"
                size="large"
                className="input-contact"
                defaultValue=""
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={18}>
        <Col span={24}>
          <Form.Item>
            {getFieldDecorator('message', {
              rules: [{ required: true, message: 'Entrer votre message svp!' }]
            })(
              <TextArea
                placeholder="Votre Message"
                className="input-contact"
                defaultValue=""
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      {isSuccess && (
        <Alert
          message="Message envoyé !"
          description="Nous vous remercions et vous recontacterons au plus vite. "
          type="success"
          banner="true"
          closable="true"
          showIcon
          style={{ marginTop: 20 }}
          ref={confirm}
        />
      )}
      {isError && (
        <Alert
          message="Une erreur est survenue !"
          description="Nous vous remercions de réessayer. "
          type="error"
          banner="true"
          closable="true"
          showIcon
          ref={confirm}
        />
      )}
      <Row>
        <Form.Item style={{ textAlign: 'center' }}>
          <Button
            htmlType="submit"
            size="large"
            className="input-contact"
            onClick="return gtag_report_conversion('https://strateg.in/#contact-us');"
          >
            Envoyer
            <SendOutlined />
          </Button>
        </Form.Item>
        {/* {recaptchaWidget && (<Recaptcha
                    sitekey="6LezvqUZAAAAABQoLymElOwR4V9rI_N67_Kzh2Mn"
                    render="explicit"
                    onloadCallback={()=>{console.log('ReCapthca chargé avec succés !')}}
                    verifyCallback={verifyCaptcha}
                  />)} */}
      </Row>
    </Form>
  );
};

const WrapperFormContact = Form.create({ name: 'form_contact' })(FormContact);

export default WrapperFormContact;
