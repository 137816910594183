import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import '../../CSS/recrutement.css';


const Recrutement = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const isMobileOrTab = useMediaQuery({ query: '(max-width: 900px)' })

  return (
    <Row>
      <Col xs={24} lg={12}>
        <Link to='recrutement/offres'>
          <div className="wrapper1">
            <div className="contrast">
              {/* <img src={imageOffre} style={{marginTop: -25}}/> */}
              <div className="img-emp" />
              <div className="overlay-recrutement">
                <h1>Espace emplois</h1>
              </div>
              {!isMobileOrTab && <div className="overlay2">
                Consultez nos offres
      </div>}

            </div>
          </div>
        </Link>
      </Col>
      <Col xs={24} lg={12}>
        <Link to="recrutement/candidature">
          <div className="wrapper1">
            <div className="contrast">
              {/* <img src={imageCandidature} style={{marginTop: -25}}/> */}
              <div className="img-cand" />
              <div className="overlay-recrutement">
                {!isMobile ? (<h1>Candidature spontanée</h1>) : (<h1>Canidature<br />Spontanée</h1>)}
              </div>
              {!isMobileOrTab && <div className="overlay2">
                Envoyez votre candidature
      </div>}
            </div>
          </div>
        </Link>
      </Col>
    </Row>
  )
}

export default Recrutement;


