import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 45px;
  height: 45px;
  position: relative;
  z-index: 1000;
  margin-top: 19px;
  margin-left: 86%;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  @media (min-width: 460px) and (max-width: 900px) {
    margin-left: 92%;
  }
`;

const Stroke1 = styled.span`
  display: block;
  position: absolute;
  z-index: 1000;
  height: 3px;
  width: 80%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  right: 0;
  top: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

  &.open {
    top: 18px;
    width: 0%;
    left: 50%;
  }
`;

const Stroke2 = styled.span`
  display: block;
  position: absolute;
  z-index: 1000;
  height: 3px;
  width: 80%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  right: 0;
  top: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

  &.open {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Stroke3 = styled.span`
  display: block;
  position: absolute;
  z-index: 1000;
  height: 3px;
  width: 80%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  right: 0;
  top: 10px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

  &.open {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;

const Stroke4 = styled.span`
  display: block;
  position: absolute;
  z-index: 1000;
  height: 3px;
  width: 80%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  right: 0;
  top: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;

  &.open {
    top: 18px;
    width: 0%;
    left: 50%;
  }
`;

const BurgerIcon = (props) => {

  const isOpen = props.openMenu;


  return (
    <Container>
      <Stroke1 className={isOpen && "open"}></Stroke1>
      <Stroke2 className={isOpen && "open"}></Stroke2>
      <Stroke3 className={isOpen && "open"}></Stroke3>
      <Stroke4 className={isOpen && "open"}></Stroke4>
    </Container>
  )
}

export default BurgerIcon