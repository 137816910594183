import React from 'react';
import NosOffres from './NosOffres';
import { useMediaQuery } from 'react-responsive';
import TransNum from './TransNum';
import NewMarkets from './NewMarkets';
import Optimisation from './Optimisation';
import Collaboraters from './Collaboraters';
import Offre33 from '../../assets/images/Offre3.webp';
import Offre22 from '../../assets/images/Offre2.webp';
import Offre1 from '../../assets/images/Offre1.webp';

import Offre33Bis from '../../assets/images/safari/Offre3.png';
import Offre22Bis from '../../assets/images/safari/Offre2.png';
import BandeauOffres from '../Bandeau/bandeauOffres';
import Offre1Bis from '../../assets/images/safari/Offre1.png';
import TransNumMobile from './TransNumMobile';
import TransNumiPade from './TransNumiPade';
import NewMarketsMobile from './NewMarketsMobile';
import NewMarketsiPade from './NewMarketsiPade';
import OptimisationMobile from './OptimisationMobile';
import OptimisationiPade from './OptimisationiPade';
import CollaboratersMobile from './CollaboratersMobile';
import CollaboratersiPade from './CollaboratersiPade';
import Training from './Trainig';


const ConseilAndFormation = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    const isiPade = useMediaQuery({ query: '(min-width: 500px) and (max-width: 1394px)' });
    const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });
    return (
        <div>
            <BandeauOffres
                image={navigator.userAgent.includes('Safari') ? Offre1Bis : Offre1}
                title="Conseil & Formation"
                text="Pour créer votre entreprise ou lui permettre de générer de la croissance, Strateg.in vous accompagne dans la conception de votre
                accélération digitale, jusqu'à l'obtention des résultats attendus."
            />
            <div style={{ zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd' }}>
                {isMobile ? <TransNumMobile /> : isiPade ? <TransNumiPade /> : <TransNum />}
                {isMobile ? <NewMarketsMobile /> : isiPade ? <NewMarketsiPade /> : <NewMarkets />}
                {isMobile ? <OptimisationMobile /> : isiPade ? <OptimisationiPade /> : <Optimisation />}
                {isMobile ? <CollaboratersMobile /> : isiPade ? <CollaboratersiPade /> : <Collaboraters />}
                <Training/>
                <NosOffres url1="/genie-logiciel" url2="/editeur-logiciel" img1={navigator.userAgent.includes('Safari') ? Offre22Bis : Offre22} img2={navigator.userAgent.includes('Safari') ? Offre33Bis : Offre33} name1="Génie logiciel & développement sur mesure" name2="Éditeur de logiciel" />
            </div>
        </div>
    );
}
export default ConseilAndFormation;
