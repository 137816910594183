import React from 'react';
import { Row, Col } from 'antd'
import { useMediaQuery } from 'react-responsive';
import Processus from '../../assets/images/image1.webp';
import ProcessusBis from '../../assets/images/safari/image1.png';


const Optimisation = () => {
    const isMobileOrTab = useMediaQuery({ query: '(max-width: 900px' })

    return (
        <div style={{ backgroundColor: '#e7e7e7' }}>
            <Row>

                <Col lg={{ span: 9 }} >
                    <img
                        src={Processus}
                        src={ProcessusBis}
                        alt="optimiser vos processus internes progiciel"
                        className="formation-images"
                    />
                </Col>
                <Col lg={{ span: 14, offset: 1 }} className="res-col" >
                    <h1>Optimiser vos processus internes</h1>
                    <p>
                        Pour l'amélioration de vos processus internes et après un audit des pratiques de votre entreprise,
                        nous vous conseillons dans le choix d'un progiciel existant sur le marché et adapté à vos
                        besoins : tableaux de bord, ERP, CRM, etc.
                    </p>
                    <p>
                        Nous prenons ensuite en charge l'implémentation de l'outil et le suivi de son utilisation par vos
                        collaborateurs.
                    </p>
                    <p>
                        Enfin,
                        nous mesurons et analysons l'amélioration des performances obtenues.
                    </p>
                </Col>
            </Row>

        </div>
    );
}
export default Optimisation;