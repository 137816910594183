import React from 'react';
import { Row, Col, Carousel } from 'antd';
import { useMediaQuery} from 'react-responsive';
import marketplace from '../../assets/images/applications/marketplace.svg'
import admin from '../../assets/images/applications/admin.svg';
import bdd from '../../assets/images/applications/bdd.svg';
import buisness from '../../assets/images/applications/buisness.svg';
import embarque from '../../assets/images/applications/embarque.svg';
import erp from '../../assets/images/applications/erp.svg';
import ia from '../../assets/images/applications/ia.svg';
import saas from '../../assets/images/applications/saas.svg';
import sitevitrine from '../../assets/images/applications/sitevitrine.svg';
import CarouselAppli from './CarouselAppli.jsx'
import '../../CSS/testappli.css';


const Applications = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  
  return ( !isMobile ? 
  <div class="container1">
        <Row>
          <Col lg={{span: 6, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={marketplace} alt="platforme marketplace"/>
                      <h3>MARKETPLACES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Vous souhaitez vendre vos produits ou mettre en relation acheteurs et vendeurs, nous concevons la platforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col lg={{span: 6, offset: 1}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={sitevitrine} alt="site vitrine et application web"/>
                      <h3>VITRINES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>Commercants, artisans, professions libérales ... Nous créons un espace personnalisé à votre image pour vous démarquer sur le web.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col lg={{span: 6, offset: 1}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={saas} alt="saas"/>
                      <h3>SAAS</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Vous souhaitez offrir vos services en ligne, nous pouvons concevoir toutes les fonctionnalités, des plus courantes aux plus avancées. </p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
          <Row>
          <Col lg={{span: 6, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={erp} alt="progiciel de gestion integree"/>
                      <h3>ERP</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Progiciel permettant de gérer et de suivre au quotidien l’ensemble des informations et des services opérationnels de votre entreprise</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col lg={{span: 6, offset: 1}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={bdd} alt="gestion de bases de donnees"/>
                      <h3>DONNÉES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Nos systèmes de bases de données (BDD)
                        sont optimisés pour pouvoir assimiler et trouver en toute sécurité toutes vos données quelque soit la taille.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col lg={{span: 6, offset: 1}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={admin}/>
                      <h3>BACK OFFICE</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Nous concevons des plates-formes
                        agiles qui vous permettent d'administrer vos solutions logicielles en toute sucurité 24H/7J. </p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
          <Row>
          <Col lg={{span: 6, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={embarque}/>
                      <h3>EMBARQUÉ</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Nous développons des logiciels industriels personnalisés, systèmes embarqués, objets connectés ..., en fonction des besoins de votre entreprise. </p>
                  </div>
              </div>
          </div>
          </Col>
          <Col lg={{span: 6, offset: 1}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={buisness}/>
                      <h3>BUSINESS</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>L'ensemble des technologies d'informatique décisionelle vous permettrons d'analyser vos données au profit de vos prises de décisions.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col lg={{span: 6, offset: 1}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={ia}/>
                      <h3>BIG DATA & IA</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Vous souhaitez développer un logiciel précurseur, nous utilisons le Big Data et l'Intelligence Artificielle pour créer le logiciel qui vous permettra de passer à l'étape supérieure.</p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
      </div>
      :
      <div class="container1">
          <CarouselAppli />
      </div>
  )
}
export default Applications;

