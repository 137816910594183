import React from 'react';
import { Row, Col } from 'antd';
import cabinet from "../../assets/images/logopartenaires/cabinetLogo.webp";
import cabinetBis from "../../assets/images/logopartenaires/cabinetLogo.png";

import enseigne from "../../assets/images/logopartenaires/enseigne angiie_burned.webp";
import citizencan from '../../assets/images/logopartenaires/citizencanLogo.webp';
import citizencanBis from '../../assets/images/logopartenaires/citizencanLogo.png';
import leasetic from "../../assets/images/logopartenaires/LeaseticLogo.png";
import skyinlab from "../../assets/images/logopartenaires/skyinlabLogo.png";
import horsearound from "../../assets/images/logopartenaires/logo-color-big.png";
import boosterActiv from "../../assets/images/logopartenaires/BoosterActivLogo-removebg-preview.png";
import thales from "../../assets/images/logopartenaires/logo_tas-removebg-preview.png";
import manjar from '../../assets/images/logopartenaires/manjar-logo.png';

const logos = [
  {
    logo: manjar,
  },
  {
    logo: leasetic,
  },
  {
    logo: thales,
  },
  {
    logo: skyinlab,
  },
  {
    logo: navigator.userAgent.includes('Safari') ? citizencanBis : citizencan,
  },
  {
    logo: navigator.userAgent.includes('Safari') ? cabinetBis : cabinet,
  },

  {
    logo: boosterActiv,
  },
  {
    logo: horsearound,
  }

];


const AvisClients = () => {

  return (
    <div className="image-client">
      <Row type="flex" justify="space-around" style={{
        padding: 100
      }}>
        {logos.map((item) => {
          return (

            <img src={item.logo} alt={item.logo} style={{ objectFit: 'contain', margin: 10, backgroundSize: 'cover', width: 250, height: 200 }} />

          )
        })}
      </Row>
    </div>
  )
}
export default AvisClients;