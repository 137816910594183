import React from 'react';
import { Card } from 'antd';
import '../../CSS/equipespartenaires.css';

const { Meta } = Card;

const CardPartenaires = (props) => {
  const {logo} = props;
  const {name} = props;
  const {content} = props;

  return (
    <Card
      className="card-partenaires"
      cover={
        <img
          style={{ backgroundSize: 'contain',  borderBottom: '1px solid rgb(212, 212, 212)', padding: 40 }}
          alt={`logo ${name}`}
          src={logo}
        />
    }
    >
      <p id="part-name">{name}</p>
      <p id="part-content">{content}</p>
    </Card>
  )
}

export default CardPartenaires;