import React from 'react';
import { Avatar, Layout, Icon, message, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import HeaderDropdwon from '../../HeaderDropdown/HeaderDropdown';
import './style.css';
import useLanguageContext from '../../../contexts/LanguageContext';

const { Header } = Layout;

const HeaderLayout = ({ collapseSider, collapsed }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { dispatch: dispatchLocale } = useLanguageContext();
  const { t } = useTranslation();

  const languages = {
    en_GB: { label: 'English', icon: '🇬🇧' },
    fr_FR: { label: 'Français', icon: '🇫🇷' },
    es_ES: { label: 'Español', icon: '🇪🇸' }
  };

  const onProfileMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatchAPI('LOGOUT');
      message.success('Logged out.');
    }
  };

  const onLanguageMenuClick = ({ key }) => {
    dispatchLocale({ type: 'SET_LOCALE', locale: key });
  };

  const langMenu = (
    <Menu onClick={onLanguageMenuClick}>
      {Object.keys(languages).map(locale => (
        <Menu.Item key={locale}>
          <span>{languages[locale].icon}</span> {languages[locale].label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const profileMenu = (
    <Menu onClick={onProfileMenuClick}>
      <Menu.Item key="profile">
        <Icon type="user" />
        {t('header.menu.user.profile')}
      </Menu.Item>
      <Menu.Item key="settings">
        <Icon type="setting" />
        {t('header.menu.user.settings')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout">
        <Icon type="logout" />
        {t('header.menu.user.logout')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Header
      style={{
        background: '#fff',
        boxShadow: '0 1px 4px rgba(0,21,41,.08)',
        padding: '0',
        zIndex: 2,
        width: '100%',
        position: 'fixed'
      }}
    >
      <Icon
        className="trigger"
        type={collapsed ? 'menu-unfold' : 'menu-fold'}
        onClick={() => collapseSider(!collapsed)}
      />
      <div className="container">
        <HeaderDropdwon overlayMenu={profileMenu}>
          <span className="dropdown-container">
            <Avatar
              size="medium"
              icon={user && user.img ? '' : 'user'}
              src={user && user.img && user.img.data ? user.img.data : ''}
            >
              {user && `${user.first_name[0]}${user.last_name[0]}`}
            </Avatar>
            <span className="full-name">{`${user.first_name} ${user.last_name}`}</span>
          </span>
        </HeaderDropdwon>
        <HeaderDropdwon overlayMenu={langMenu}>
          <span className="dropdown-container">
            <Icon type="global" />
          </span>
        </HeaderDropdwon>
      </div>
    </Header>
  );
};

export default HeaderLayout;
