import React from 'react';
import styled from 'styled-components';
import image from '../../assets/images/mentionslegales.webp';
import Bandeau from '../Bandeau/bandeau.jsx';

const Text = styled.div`
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;

  @media (max-width: 900px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

const MentionsLegales = () => {
  return (
    <div>
      <Bandeau image={image} title="Mentions Légales" />
      <Text style={{ marginTop: 50 }}>Merci de lire avec attention les différentes modalités d’utilisation du présent site avant de le parcourir. En vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet http://www.strateg.in sont : </Text>
      <h1 id='offre_barre'>Editeur du Site</h1>
      <Text>SASU STRATEG IN N°SIRET : 825 025 406 00018 Responsable éditorial : Yohan Rousseau Email : contact@strateg.in Site Web : http://www.strateg.in</Text>
      <h1 id='offre_barre'>Hébergement</h1>
      <Text>Hébergeur : 1&1 Internet SARL 7, place de la Gare BP 70109 57201 Sarreguemines Cedex Site Web : https://www.1and1.fr</Text>
      <h1 id='offre_barre'>Développement</h1>
      <Text>SASU STRATEG IN Site Web : http://www.strateg.in</Text>
      <h1 id='offre_barre'>Conditions d’utilisation</h1>
      <Text>Ce site (http://www.strateg.in) est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…) pour un meilleur confort d'utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Internet Explorer, Safari, Firefox, Google Chrome, etc… </Text>
      <Text>STRATEG IN met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour fiable de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L'internaute devra donc signaler toutes modifications du site qu'il jugerait utile. STRATEG IN n'est en aucun cas responsable de l'utilisation faite de ces informations, et de tout préjudice direct ou indirect pouvant en découler. </Text>
    </div>
  );
};

export default MentionsLegales;