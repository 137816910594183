import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Isotope from 'isotope-layout';
import { useMediaQuery } from 'react-responsive';
import { Button, Radio, Row, Card, Menu, Dropdown, Icon } from 'antd';
import CardOffre from './CardOffre.jsx';
import imagePasOffre from '../../assets/images/aucunposte.png';
require('isotope-cells-by-row');


const IsotopeReact = ({offres}) => {
  // store the isotope object in one state
  const [isotope, setIsotope] = useState(null);
  // store the filter keyword in another state
  const [filterKey, setFilterKey] = useState("*");
  const [triDate, setTriDate] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const date = new Date()

  const menu = (
    <Menu style={{display: 'flex', flexDirection: 'column'}}>
      <Button onClick={() => setFilterKey("*")} value='all'>Tous</Button>
      <Button onClick={()=> setTriDate(true)} value='anciens'>+Anciens</Button>
      <Button onClick={()=> setTriDate(false)} value='recents'>+ Récents</Button>
      <Button onClick={() => setFilterKey("cdi")} value='cdi'>CDI</Button>
      <Button onClick={() => setFilterKey("stage")} value='stage'>Stage</Button>
      <Button onClick={() => setFilterKey("fullstack")} value='fullstack'>Fullstack</Button>
      <Button onClick={() => setFilterKey("embarque")} value='embarque'>Embarque</Button>
      <Button onClick={() => setFilterKey("web")} value='web'>Web</Button>
      <Button onClick={() => setFilterKey("marketing")} value='marketing'>Marketing</Button>
      <Button onClick={() => setFilterKey("python")} value='python'>Python</Button>
    </Menu>
  );
 
  // initialize an Isotope object with configs
  useEffect(() => {
    setIsotope(
      new Isotope(".filter-container", {
        itemSelector: ".filter-item",
        layoutMode: 'cellsByRow',
        cellsByRow: {
          columnWidth: 310,
          rowHeight: 450
        },
        sortBy: 'number'
      })
    );
  }, []);

  // handling filter key change
  useEffect(
    () => {
      if (isotope) {
        filterKey === "*"
          ? isotope.arrange({ filter: `:not(.none)` })
          : isotope.arrange({ filter: `.${filterKey}` });
        
        triDate === true
          ? isotope.arrange({sortAscending: true})
          : isotope.arrange({sortAscending: false});

        isotope.filteredItems.length === 0 && isotope.arrange({filter: `.none`})
      }
    },
    [isotope, filterKey, triDate]
  );

  return (
    <>
    {isMobile ? (
        <Dropdown overlay={menu} trigger={['click']} >
          <Row style={{display: 'flex', justifyContent: 'center', marginTop: 23}}>
          <Button>Filtres <Icon type="down" /></Button>
          </Row>
        </Dropdown>
        )
        :
        (
        <Row style={{display: 'flex', justifyContent: 'center', margin: 50, marginBottom: 30}}>
        <Radio.Group style={{margin: 'auto'}}>
          <Radio.Button onClick={() => setFilterKey("*")} value='all'>Tous</Radio.Button>
          <Radio.Button onClick={()=> setTriDate(true)} value='anciens'>+Anciens</Radio.Button>
          <Radio.Button onClick={()=> setTriDate(false)} value='recents'>+ Récents</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("cdi")} value='cdi'>CDI</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("stage")} value='stage'>Stage</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("fullstack")} value='fullstack'>Fullstack</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("embarque")} value='embarque'>Embarqué</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("web")} value='web'>Web</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("marketing")} value='marketing'>Marketing</Radio.Button>
          <Radio.Button onClick={() => setFilterKey("python")} value='python'>Python</Radio.Button>
        </Radio.Group>
        </Row>
        )}
            <ul className="filter-container">
              {offres.map(offre => { 
              return (
                <div className={`filter-item ${offre.tags.replace(",", "")}`}>
                  <span><p className="number" style={{visibility: 'hidden'}}>{offres.indexOf(offre)}</p>
                  <Link to={{
                pathname:  `/recrutement/offres/${offre._id}`,
                state:  offre
              }}>
                    <CardOffre offre={offre} />
                  </Link>
                </span>
              </div>
            )})}
            <div className="filter-item none">
                  <span><p className="number" style={{visibility: 'hidden'}}>100</p>
                  <Card
                      className='dispo'
                      style={{ width: 270, height: 410, borderRadius: 10 }}
                      cover={
                        <div className="hvrbox">
                          <img src={imagePasOffre} className="hvrbox-layer_bottom"/>
                        </div>
                        }>
                        <div className="poste">
                          <p id="poste-date">le {date.toLocaleDateString('fr-FR')}</p>
                      </div>
                      <p id="poste-title">Actuellement aucun poste disponible.</p>
                    </Card>
                </span>
              </div>
          </ul>
          <div>
        <Link to='/recrutement/candidature'>
      <h2 id="link_content">Aucun poste ne vous correspond ? Envoyez-nous votre candidature !</h2>
      <h1 id="link_title"> {"> Candidature spontanée <"}</h1>
      </Link>
        </div>
    </>
  );
};

export default IsotopeReact;