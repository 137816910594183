import React from 'react';
import { Carousel, Card } from 'antd';
import Coeso from '../../assets/images/coeso_screens.webp';
import Cookize from '../../assets/images/cookize_screens.webp';
import Findee from '../../assets/images/findee_screens.webp';
import Horsearound from '../../assets/images/horsearound_screens.webp';

import CoesoBis from '../../assets/images/safari/coeso_screens.png';
import CookizeBis from '../../assets/images/safari/cookize_screens.png';
import FindeeBis from '../../assets/images/safari/findee_screens.png';
import HorsearoundBis from '../../assets/images/safari/horsearound_screens.png';

import is from '../../assets/images/impotstop_screens.png';
import mcr from '../../assets/images/mcr_screens.png';
import '../../CSS/realisations.css';

const { Meta } = Card;

const MyCarousel = () => {
  return (
    <Carousel className="carousel-realisations" autoplay>
      <div>
        <a href="https://horse-around.com/" target="_blank">
          <Card
            id="card-realisation"
            bordered
            style={{ backgroundColor: 'white' }}
            cover={<img alt="horsearound" src={Horsearound} src={HorsearoundBis} />}
          >
            <Meta
              title="HORSE-AROUND"
              description="Plateforme qui regroupe tout l'univers équestre : 
                vacances, séjours, randonnées.."
            />
          </Card>
        </a>
      </div>
      <div>
        <a href="https://app.cookize.strateg.in/" target="_blank">
          <Card
            id="card-realisation"
            bordered
            style={{ backgroundColor: 'white' }}
            cover={<img alt="cookize" src={Cookize} src={CookizeBis} />}
          >
            <Meta
              title="COOK'IZE"
              description="Cook’ize vous permet de planifier des menus complets et diversifiés en un clic!"
            />
          </Card>
        </a>
      </div>
      <div>
        <a href="https://app.findee.io/login" target="_blank">
          <Card
            id="card-realisation"
            bordered
            style={{ backgroundColor: 'white' }}
            cover={<img alt="findee" src={Findee} src={FindeeBis} />}
          >
            <Meta
              title="FINDEE"
              style={{ color: 'black', textAlign: 'center' }}
              description="Avec Findee, boostez votre business grâce à l'intelligence artificielle !"
            />
          </Card>
        </a>
      </div>
      <div>
        <a href="http://www.impot-stop.com/" target="_blank">
          <Card
            id="card-realisation"
            bordered
            style={{ backgroundColor: 'white' }}
            cover={<img alt="impots stop" src={is} />}
          >
            <Meta
              title="IMPOTS STOP"
              description="Réduisez vos impôts grâce aux experts en optimisation fiscal de stop-impots.fr"
            />
          </Card>
        </a>
      </div>
      <div>
        <a href="https://app.mcrv2.strateg.in/" target="_blank">
          <Card
            id="card-realisation"
            bordered
            style={{ backgroundColor: 'white' }}
            cover={<img alt="my contract report" src={mcr} />}
          >
            <Meta
              title="MY CONTRACT REPORT"
              description="Apporte une nouvelle approche du pilotage et du contrôle de vos contrats"
            />
          </Card>
        </a>
      </div>
      <div>
        <a href="http://app.coesourcing.com" target="_blank">
          <Card
            id="card-realisation"
            bordered
            style={{ backgroundColor: 'white' }}
            cover={<img alt="coeso" src={Coeso} src={CoesoBis} />}
          >
            <Meta
              title="COESO"
              description="Faciliter la gestions de vos achats, et rencontrer de nouveaux fournisseurs."
            />
          </Card>
        </a>
      </div>
    </Carousel>
  )
}
export default MyCarousel;
