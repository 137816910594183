import React from "react";
import { Dropdown } from "antd";

const HeaderDropdown = ({ children, overlayMenu }) => {
  return (
    <Dropdown className="dropdown-container-wrapper" overlay={overlayMenu}>
      {children}
    </Dropdown>
  );
};

export default HeaderDropdown;
