import React from 'react';
import { Row, Col, Button, Progress } from 'antd';
import StrateginSoft from '../../assets/images/logiciel strategin.jpg';
import StrateginSoftMobile from '../../assets/images/logiciel mobile strategin.jpg';
import StrateginToolPic from '../../assets/images/outil strategin.jpg';
import Program1 from '../../assets/training/Programme 1.pdf';
import Program2 from '../../assets/training/Programme 2.pdf';
import Program3 from '../../assets/training/Programme 3.pdf';
import Program4 from '../../assets/training/Programme 4.pdf';
import Program5 from '../../assets/training/Programme 5.pdf';
import Program6 from '../../assets/training/Programme 6.pdf';


const Training = () => {
  return (
    <div style={{ backgroundColor: '#e7e7e7' }}>
      <div span={8} className="nostrainigs-title">
        <h1>Nos formations</h1>
      </div>
      <Row
        type="flex"
        justify="center"
        style={{ paddingBottom: 40 }}
        gutter={[8, 50]}
      >
        <Col xxl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              width: 400,
              height: 200,
              margin: 'auto'
            }}
          >
            <img
              src={StrateginSoftMobile}
              alt="project management"
              style={{ height: '110%', width: '100%' }}
            />
          </div>
          <div className="text-bloc2">
            <h1>
              APPRENDRE A UTILISER L’OUTIL-LOGICIEL DE STRATEG.IN – FORMATION
              SUR MESURE
            </h1>
            <p>
              Maîtriser les techniques et principes de base pour utiliser
              efficacement le logiciel
            </p>
            <p style={{ fontSize: 14 }}>Nombre de stagiaires : 1</p>
            <p style={{ fontSize: 14 }}>
              Date de formation: du 09-05-2022 au 30-05-2022
            </p>
            <p style={{ fontSize: 14 }}>Taux de satisfaction</p>
            <div style={{ marginBottom: 25 }}>
              <Progress
                strokeLinecap="square"
                type="circle"
                strokeColor="#063aa9"
                percent={99}
                width={60}
                format={percent => `${percent} %`}
              />
            </div>
            <a
              href={Program1}
              target="_blank"
              download="APPRENDRE A UTILISER L’OUTIL-LOGICIEL DE STRATEG.IN.pdf"
            >
              <Button type="primary" style={{ fontSize: 14 }}>
                Télécharger le programme de formation
              </Button>
            </a>
          </div>
        </Col>
        <Col xxl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              width: 400,
              height: 200,
              margin: 'auto'
            }}
          >
            <img
              src={StrateginSoft}
              alt="project management"
              style={{ height: '110%', width: '100%' }}
            />
          </div>
          <div className="text-bloc2">
            <h1>
              APPRENDRE A UTILISER LE LOGICIEL STRATEG.IN – FORMATION SUR MESURE
            </h1>
            <p>
              Maitriser les techniques et principes de base pour utiliser
              efficacement le logiciel
            </p>
            <p style={{ fontSize: 14 }}>Nombre de stagiaires : 1</p>
            <p style={{ fontSize: 14 }}>
              Date de formation: du 22-11-2021 au 03-12-2021
            </p>
            <p style={{ fontSize: 14 }}>Taux de satisfaction</p>
            <div style={{ marginBottom: 25 }}>
              <Progress
                strokeLinecap="square"
                type="circle"
                strokeColor="#063aa9"
                percent={93}
                width={60}
                format={percent => `${percent} %`}
              />
            </div>
            <a
              href={Program2}
              target="_blank"
              download="APPRENDRE A UTILISER LE LOGICIEL STRATEG.IN.pdf"
            >
              <Button type="primary" style={{ fontSize: 14 }}>
                Télécharger le programme de formation
              </Button>
            </a>
          </div>
        </Col>
        <Col xxl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              width: 400,
              height: 200,
              margin: 'auto'
            }}
          >
            <img
              src={StrateginToolPic}
              alt="project management"
              style={{ height: '110%', width: '100%' }}
            />
          </div>
          <div className="text-bloc2">
            <h1>
              APPRENDRE À UTILISER LE LOGICIEL STRATEG.IN – FORMATION SUR MESURE
            </h1>
            <p>
              Maîtriser les techniques et principes de base pour utiliser
              efficacement les outils
            </p>
            <p style={{ fontSize: 14 }}>Nombre de stagiaires : 1</p>
            <p style={{ fontSize: 14 }}>
              Date de formation: du 13-12-2021 au 14-12-2021
            </p>
            <p style={{ fontSize: 14 }}>Taux de satisfaction</p>
            <div style={{ marginBottom: 25 }}>
              <Progress
                strokeLinecap="square"
                type="circle"
                strokeColor="#063aa9"
                percent={91}
                width={60}
                format={percent => `${percent} %`}
              />
            </div>
            <a
              href={Program3}
              target="_blank"
              download="APPRENDRE A UTILISER L’OUTIL STRATEG.IN.pdf"
            >
              <Button type="primary" style={{ fontSize: 14 }}>
                Télécharger le programme de formation
              </Button>
            </a>
          </div>
        </Col>
        <Col xxl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              width: 400,
              height: 200,
              margin: 'auto'
            }}
          >
            <img
              src={StrateginToolPic}
              alt="project management"
              style={{ height: '110%', width: '100%' }}
            />
          </div>
          <div className="text-bloc2">
            <h1>
              APPRENDRE A UTILISER LE LOGICIEL ET L’APPLICATION MOBILE
              STRATEG.IN – FORMATION SUR MESURE
            </h1>
            <p>
              Maîtriser les techniques et principes de base pour utiliser
              efficacement le logiciel et l’application mobile
            </p>
            <p style={{ fontSize: 14 }}>Nombre de stagiaires : 2</p>
            <p style={{ fontSize: 14 }}>
              Date de formation: du 13-12-2021 au 24-12-2021
            </p>
            <p style={{ fontSize: 14 }}>Taux de satisfaction</p>
            <div style={{ marginBottom: 25 }}>
              <Progress
                strokeLinecap="square"
                type="circle"
                strokeColor="#063aa9"
                percent={97}
                width={60}
                format={percent => `${percent} %`}
              />
            </div>
            <a
              href={Program4}
              target="_blank"
              download="APPRENDRE A UTILISER LE LOGICIEL ET L’APPLICATION MOBILE STRATEG.IN.pdf"
            >
              <Button type="primary" style={{ fontSize: 14 }}>
                Télécharger le programme de formation
              </Button>
            </a>
          </div>
        </Col>
        <Col xxl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              width: 400,
              height: 200,
              margin: 'auto'
            }}
          >
            <img
              src={StrateginToolPic}
              alt="project management"
              style={{ height: '110%', width: '100%' }}
            />
          </div>
          <div className="text-bloc2">
            <h1>
              APPRENDRE À UTILISER LES OUTILS STRATEG.IN – FORMATION SUR MESURE
            </h1>
            <p>
              Maîtriser les techniques et principes de base pour utiliser
              efficacement les outils
            </p>
            <p style={{ fontSize: 14 }}>Nombre de stagiaires : 3</p>
            <p style={{ fontSize: 14 }}>
              Date de formation: du 02-05-2022 au 11-05-2022
            </p>
            <p style={{ fontSize: 14 }}>Taux de satisfaction</p>
            <div style={{ marginBottom: 25 }}>
              <Progress
                strokeLinecap="square"
                type="circle"
                strokeColor="#063aa9"
                percent={96}
                width={60}
                format={percent => `${percent} %`}
              />
            </div>
            <a
              href={Program5}
              target="_blank"
              download="APPRENDRE A UTILISER LES OUTILS STRATEG.IN –.pdf"
            >
              <Button type="primary" style={{ fontSize: 14 }}>
                Télécharger le programme de formation
              </Button>
            </a>
          </div>
        </Col>
        <Col xxl={{ span: 8 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              overflow: 'hidden',
              width: 400,
              height: 200,
              margin: 'auto'
            }}
          >
            <img
              src={StrateginToolPic}
              alt="project management"
              style={{ height: '110%', width: '100%' }}
            />
          </div>
          <div className="text-bloc2">
            <h1>
              APPRENDRE A MAITRISER LES TECHNOLOGIES DE L’OUTIL - FORMATION
              SUR-MESURE
            </h1>
            <p>
              Maîtriser les techniques et principes de base pour utiliser
              efficacement le logiciel pour sa maintenance
            </p>
            <p style={{ fontSize: 14 }}>Nombre de stagiaires : 2</p>
            <p style={{ fontSize: 14 }}>
              Date de formation: Le 24, 29 et 31 mars 2023
            </p>
            <p style={{ fontSize: 14 }}>Taux de satisfaction</p>
            <div style={{ marginBottom: 25 }}>
              <Progress
                strokeLinecap="square"
                type="circle"
                strokeColor="#063aa9"
                percent={91}
                width={60}
                format={percent => `${percent} %`}
              />
            </div>
            <a
              href={Program6}
              target="_blank"
              download="APPRENDRE A MAITRISER LES TECHNOLOGIES DE L’OUTIL.pdf"
            >
              <Button type="primary" style={{ fontSize: 14 }}>
                Télécharger le programme de formation
              </Button>
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Training;
