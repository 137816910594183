import React from 'react';
import { Row, Col, Carousel } from 'antd';
import { useMediaQuery} from 'react-responsive';
import { SwapLeftOutlined, SwapRightOutlined } from '@ant-design/icons';
import marketplace from '../../assets/images/applications/marketplace.svg'
import admin from '../../assets/images/applications/admin.svg';
import bdd from '../../assets/images/applications/bdd.svg';
import buisness from '../../assets/images/applications/buisness.svg';
import embarque from '../../assets/images/applications/embarque.svg';
import erp from '../../assets/images/applications/erp.svg';
import ia from '../../assets/images/applications/ia.svg';
import saas from '../../assets/images/applications/saas.svg';
import sitevitrine from '../../assets/images/applications/sitevitrine.svg';
import '../../CSS/testappli.css';

const CarouselAppli = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })

  return( !isMobile ? 
    <>
    <SwapLeftOutlined style={{position: 'absolute', top:185, left: 20, color: '#3242a8', fontSize: 20}} />
    <Carousel className="carousel-applications" autoplay>
        <Row>
          <Col md={{span: 10, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={marketplace} alt="platforme marketplace"/>
                      <h3>MARKETPLACES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col md={{span: 10, offset: 0}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={sitevitrine} alt="site vitrine et application web"/>
                      <h3>VITRINES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>Commerçants, artisans, professions libérales ... Nous créons un espace personnalisé, à votre image pour vous démarquer sur le web.</p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
          <Row>
          <Col md={{span: 10, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={saas} alt="saas"/>
                      <h3>SAAS</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                      <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col md={{span: 10, offset: 0}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={erp} alt="progiciel de gestion integree"/>
                      <h3>ERP & CRM</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
          <Row>
          <Col md={{span: 10, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={bdd} alt="gestion de bases de donnees"/>
                      <h3>DONNÉES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col md={{span: 10, offset: 0}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={admin} alt="administration du logiciel"/>
                      <h3>BACK OFFICE</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
          <Row>
          <Col md={{span: 10, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={embarque} alt="logiciel industriel personalise systeme embarque"/>
                      <h3>EMBARQUÉ</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          <Col md={{span: 10, offset: 0}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={buisness} alt="technologies d informatique decisionelle"/>
                      <h3>BUSINESS</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
          <Row>
          <Col md={{span: 20, offset: 2}} style={{display: 'flex', justifyContent: 'center'}}>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={ia} alt="intelligence artificielle et big data"/>
                      <h3>BIG DATA & IA</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Col>
          </Row>
          </Carousel>
          <SwapRightOutlined style={{position: 'absolute', top:185, right: 20, color: '#3242a8', fontSize: 20}} />
          </>
          :
          <>
          <SwapLeftOutlined style={{position: 'absolute', top:185, left: 10, color: '#3242a8', fontSize: 20}} />
          <Carousel className="carousel-applications" autoplay>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={marketplace} alt="platforme marketplace"/>
                      <h3>MARKETPLACES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={sitevitrine} alt="site vitrine et application web"/>
                      <h3>VITRINES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={saas} alt="saas"/>
                      <h3>SAAS</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={erp} alt="progiciel de gestion integree"/>
                      <h3>ERP & CRM</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={bdd} alt="gestion de bases de donnees"/>
                      <h3>DONNÉES</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={admin}  alt="administration du logiciel"/>
                      <h3>BACK OFFICE</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                      <img src={embarque} alt="logiciel industriel personalise systeme embarque"/>
                      <h3>EMBARQUÉ</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={buisness} alt="technologies d informatique decisionelle"/>
                      <h3>BUSINESS</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          <div class="card">
              <div class="face face1">
                  <div class="content">
                  <img src={ia} alt="intelligence artificielle et big data"/>
                      <h3>BIG DATA & IA</h3>
                  </div>
              </div>
              <div class="face face2">
                  <div class="content">
                  <p>De la petite boutique en ligne aux géants du E-commerce, nous concevons la plateforme adaptée à vos besoins.</p>
                  </div>
              </div>
          </div>
          </Carousel>
          <SwapRightOutlined style={{position: 'absolute', top:185, right: 10, color: '#3242a8', fontSize: 20}} />
          </>
  )
}
export default CarouselAppli;

        