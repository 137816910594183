import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import image2 from '../../assets/images/category2.webp';
import image1 from '../../assets/images/offreemploi1.webp';
import image2Bis from '../../assets/images/safari/category2.png';
import image1Bis from '../../assets/images/safari/offreemploi1.png';




const CardOffre = ({ offre }) => {
  const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;
  const date = new Date(offre.createdAt);
  const [imageOffre, setImageOffre] = useState(navigator.userAgent.includes('Safari') ? image1Bis : image1)

  useEffect(() => {
    if (offre.developpeur) {
      setImageOffre(navigator.userAgent.includes('Safari') ? image2Bis : image2)
    }
  })

  return (
    <Card
      className={offre.indisponible ? 'indispo' : 'dispo'}
      style={{ width: 270, height: 410, borderRadius: 10 }}
      cover={
        <div className="hvrbox">
          <img src={imageOffre} alt="offre d emploi recrutement" className="hvrbox-layer_bottom" />
          <div className="hvrbox-layer_top">
            <div className="hvrbox-text">
              Poste indisponible
              </div>
          </div>
        </div>
      }>
      <div className="poste">
        <p id="poste-date">publié le {date.toLocaleDateString('fr-FR')}</p>
      </div>
      <p id="poste-title">{offre.title}</p>
      {/* <div className="offre_strapi" dangerouslySetInnerHTML={{__html: offre.shortdesc}} /> */}
    </Card>
  )
}
export default CardOffre;