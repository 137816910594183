import React from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import AGILE from './AgileDescription';
import Applications from './testAppli.jsx';
import Methodologie from './Methodologie';
import Mongodb from '../../assets/images/mongo_db_logo_small.png';
import Nodejs from '../../assets/images/node_250.png';
import Python from '../../assets/images/python_logo_small.png';
import Reactjs from '../../assets/images/react.png';
import Symfony from "../../assets/images/synfony_black_icon_small.png";
import JS from '../../assets/images/js_new_120.png';
import NosOffres from './NosOffres';
import Offre11 from '../../assets/images/Offre1.webp';
import Offre33 from '../../assets/images/Offre3.webp';
import Offre2 from '../../assets/images/Offre3.webp';
import Offre11Bis from '../../assets/images/safari/Offre1.png';
import Offre33Bis from '../../assets/images/safari/Offre3.png';
import Offre2Bis from '../../assets/images/safari/Offre3.png';
import BandeauOffres from '../Bandeau/bandeauOffres';


const logos = [
  {
    logo: Mongodb,
  },
  {
    logo: Nodejs,
  },
  {
    logo: Python,
  },
  {
    logo: Reactjs,
  },
  {
    logo: Symfony,
  },
  {
    logo: JS,
  },
];


const GenieLogiciel = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const isIpade = useMediaQuery({ query: '(max-width: 1024px) and (min-width: 500px)' });
  return (
    <div>
      <BandeauOffres
        image={navigator.userAgent.includes('Safari') ? Offre2Bis : Offre2}
        title="Génie logiciel & Développement sur mesure"
        text="Pour vos logiciels embarqués, application SaaS ou mobiles, nous prenons en
                charge l'ensemble de vos projets digitaux grâce à une offre intégrée,
                depuis la définition de la stratégie jusqu'au développement logiciel.
                Nous optimisons chaque étape en utilisant notamment la méthode AGILE."
      />
      <div style={{ zIndex: 3, position: 'relative', backgroundColor: '#fdfdfd' }}>
        <AGILE />
        <h1 id="offre_barre_appli">Toutes les applications dont vous rêviez</h1>
        <Row style={{ marginTop: '50px' }}>
          <Applications />
        </Row>
        <h1 id="offre_barre">Notre méthodologie</h1>
        <Methodologie />
        {isMobile ?
          <div>
            {logos.map((item) => {
              return (
                <Row type="flex" style={{ justifyContent: 'center', margin: '30px' }}>
                  <img src={item.logo} alt={item.logo} />
                </Row>
              )
            })}
          </div>
          : isIpade ?
            <div>
              {logos.map((item) => {
                return (
                  <Row type="flex" style={{ justifyContent: 'center', margin: '30px' }}>
                    <img src={item.logo} alt={item.logo} />
                  </Row>
                )
              })}
            </div>
            :
            <Row type="flex" justify="space-around" style={{
              marginTop: '100px',
              marginBottom: '50px',
              padding: '10px'
            }}>
              {logos.map((item) => {
                return (
                  <Col >
                    <img src={item.logo} alt={item.logo} />
                  </Col>
                )
              })}
            </Row>

        }
        <NosOffres url1="/conseil" url2="/editeur-logiciel" img1={navigator.userAgent.includes('Safari') ? Offre11Bis : Offre11} img2={navigator.userAgent.includes('Safari') ? Offre33Bis : Offre33} name1="Conseil & formation" name2="Éditeur de logiciel" />
      </div>
    </div>
  );
};

export default GenieLogiciel;
