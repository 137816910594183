import React from 'react';
import { Row, Col } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import '../../CSS/offrespage.css';

const Offres = () => {
  const isMobile = useMediaQuery({query: '(max-width: 900px)'})
  return (
    <div>
      <Row className="row-offres">
        <Col lg={8} xs={24} className="col-offres">
          <Link to="/conseil">
          <div className="wrapper">
            <div className="contrast">
              <div className="img-offre1"/>
              {/* <img src={Offre1}  /> */}
              <div className="overlay-offre">
                <div>Conseil & Formation</div>
              </div>
              {!isMobile && <div className="overlay22">
                    Un accompagnement personnalisé dans l’accélération numérique …
              </div>}
              
            </div>
          </div>
          </Link>
        </Col>
        <Col lg={8} xs={24} className="col-offres">
          <Link to="/genie-logiciel">
            <div className="wrapper">
              <div className="contrast">
              <div className="img-offre2"/>
                {/* <img src={Offre2} /> */}
                <div className="overlay-offre">
                  <div>Génie logiciel <br/>&<br/> Développement sur mesure</div>
                </div>
                {!isMobile && <div className="overlay22">
                    De la conception au déploiement, nos logiciels sur mesure sont parfaitement adaptés à vos besoins …
                </div>}
                
              </div>
           </div>
          </Link>
        </Col>
        <Col lg={8} xs={24} className="col-offres">
          <Link to="/editeur-logiciel">
            <div className="wrapper">
              <div className="contrast">
              <div className="img-offre3"/>
                {/* <img src={Offre3} /> */}
                <div className="overlay-offre">
                  <div>Éditeur de logiciel</div>
                </div>
                {!isMobile && <div className="overlay22">
                    Découvrez nos logiciels innovants et prêts à l’emploi …
                </div>}
                
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Offres;
