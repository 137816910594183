import React, { useState, useRef } from 'react';
import { Form, Input, Card, Col, Row, Alert } from 'antd';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import MyCarousel from './Carousel';
import { useMediaQuery } from 'react-responsive';
import assurmax from '../../assets/images/mockup_assurmax.png';
import FindeeBis from '../../assets/images/safari/findee_screens.png';
import HorsearoundBis from '../../assets/images/safari/horsearound_screens.png';
import mcr from '../../assets/images/mockup_mcr.png';
import demateriz from '../../assets/images/mockup_demateriz.png';
import AvisClients from './AvisClients';
import '../../CSS/realisations.css';

const { Meta } = Card;
const { Search } = Input

const Realisations = () => {
  const confirm = useRef()
  const [isSuccess, setIsSuccess] = useState(false)
  const isMobile = useMediaQuery({ query: '(max-width: 900px)' })
  const [recaptchaWidget, setRecaptchaWidget] = useState(false)
  const [email, setEmail] = useState("")
  const [isError, setIsError] = useState(false)


  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const onNewsletter = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setRecaptchaWidget(false)
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER}/newsletter`,
        data: {
          email: email,
        }
      })
        .then((res) => {
          setIsSuccess(true)
          setEmail("")
        })
        .catch(function (err) {
          setIsError(true)
          setEmail("")
        });
    } else {
      alert("Merci de saisir un addresse email valide !")
    }
  }

  const verifyCaptcha = (response) => {
    if (response) {
      setRecaptchaWidget(false)
      onNewsletter()
    }
  }

  const onSearch = (value) => {
    setEmail(value)
    setRecaptchaWidget(true)
  }

  return (
    <div className="site-card-wrapper" style={{ backgroundColor: '#fdfdfd' }}>
      <div style={{ backgroundColor: '#fdfdfd', height: 1 }}></div>
      <h1 id="offre_barre"> Nos réalisations </h1>
      {!isMobile ?
        (<div style={{maxWidth: 1420, margin: '0 auto'}}>
          <Row
            type="flex"
            justify='center'
            style={{
              marginBottom: '20px',
              padding: '0 32px'
            }}
            gutter={[48, 32]}
          >
            <Col lg={{ span: 8 }}>
              <a href="https://extranet.groupe-assurmax.com" target="_blank">
                <Card
                  id="card-realisation"
                  bordered
                  style={{ backgroundColor: 'white' }}
                  cover={<img alt="ASSURMAX" src={assurmax} />}
                >
                  <Meta
                    id="meta-description"
                    title="ASSURMAX"
                    description="Assurmax met à votre disposition des contrats simples & complets à forte valeur ajoutée."
                  />
                </Card>
              </a>
            </Col>
            <Col lg={{ span: 8 }}>
              <a href="https://app.demateriz.fr" target="_blank">
                <Card
                  id="card-realisation"
                  bordered
                  style={{ backgroundColor: 'white' }}
                  cover={<img alt="Demateriz" src={demateriz} />}
                >
                  <Meta
                    title="Demateriz"
                    id="meta-description"
                    description="Plateforme de gestion d'organismes de formation."
                  />
                </Card>
              </a>
            </Col>
            <Col lg={{ span: 8}}>
              <a href="https://app.findee.io" target="_blank">
                <Card
                  id="card-realisation"
                  bordered
                  style={{ backgroundColor: 'white' }}
                  cover={<img alt="findee" src={FindeeBis} />}
                >
                  <Meta
                    title="FINDEE"
                    id="meta-description"
                    style={{ color: 'black', textAlign: 'center' }}
                    description="Avec Findee, boostez votre business grâce à l'intelligence artificielle !"
                  />
                </Card>
              </a>
            </Col>
            <Col lg={{ span: 8 }}>
              <a href="https://horse-around.com/" target="_blank">
                <Card
                  id="card-realisation"
                  bordered
                  style={{ backgroundColor: 'white' }}
                  cover={<img alt="horsearound" src={HorsearoundBis} />}
                >
                  <Meta
                    title="HORSE-AROUND"
                    id="meta-description"
                    description="Plateforme de mise en relation dans l'univers équestre :
                vacances, séjours, randonnées..."
                  />
                </Card>
              </a>
            </Col>
            <Col lg={{ span: 8 }}>
              <a href="https://mycontractreport.com" target="_blank">
                <Card
                  id="card-realisation"
                  bordered
                  style={{ backgroundColor: 'white' }}
                  cover={<img alt="my contract report" src={mcr} />}
                >
                  <Meta
                    title="MY CONTRACT REPORT"
                    id="meta-description"
                    description="Logiciel de gestion integrée permettant le pilotage des contrats de leasing."
                  />
                </Card>
              </a>
            </Col>
          </Row>
        </div>)
        :
        <MyCarousel />}
      <Row className="newslater">
        <Col lg={{ span: 10, offset: 2 }} id="col1-newslater">
          <h1 id="newslater-h1">Recevoir la newsletter</h1>
          <p style={{ color: 'white', fontSize: 16 }}>
            Recevez une fois par mois (maximum) l'actualité de Strateg.in
                </p>
        </Col>
        <Col lg={10} id="col2-newslater">
          <Form layout="inline">
            <Search
              placeholder="email"
              enterButton="GO !"
              size="large"
              onChange={handleChange}
              value={email}
              onSearch={onSearch}
            />
            {recaptchaWidget && (<Recaptcha
              sitekey="6LezvqUZAAAAABQoLymElOwR4V9rI_N67_Kzh2Mn"
              render="explicit"
              verifyCallback={verifyCaptcha}
            />)}
          </Form>
        </Col>
      </Row>
      <Row>{isSuccess && (<Alert
        message="Demande envoyée !"
        description="Nous vous remercions et sommes heureus de vous tenir informer des nouveautés. "
        type="success"
        banner="true"
        closable="true"
        showIcon
        ref={confirm}
      />)}
        {isError && (<Alert
          message="Une erreur est survenue !"
          description="Nous vous remercions de réessayer ou de nous contacter si cela se reproduit. "
          type="error"
          banner="true"
          closable="true"
          showIcon
          ref={confirm}
        />)}</Row>
      <h1 id="offre_barre">  Ils nous font confiance </h1>
      <Row >
        <Col lg={24} style={{ marginBottom: 50 }}>
          <AvisClients />
        </Col>
      </Row>
    </div>
  );
};
export default Realisations;
