import React, { useState, useEffect, useRef } from 'react'
import { Form, Input, Button, Row, Col, Select, Alert, Upload, Icon, message } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import DragnDrop from './DragAndDrop'
import axios from 'axios';
import Recaptcha from 'react-recaptcha';

const { Option } = Select;
const Dragger = Upload;

const FormCandidature = ({ offre, form }) => {
  const confirm = useRef()
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isSpontane, setIsSpontane] = useState(true)
  const [cvpath, setCvpath] = useState([])
  const [showDndList, setshowDndList] = useState(true)
  const [captchaValidate, setCaptchaValidate] = useState(true)
  const [recaptchaWidget, setRecaptchaWidget] = useState(false)
  const { getFieldDecorator } = form


  useEffect(()=> {
    if (window.location.pathname.split('/')[1] === 'offres'){
      setIsSpontane(false)
    }
  })
  
  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        // if (!captchaValidate) {
        //   setRecaptchaWidget(true)
        // } else {
        //   setRecaptchaWidget(false)
        axios({
          method: 'post',
          url: `${process.env.REACT_APP_SERVER}/candidature`,
          data: {
            poste: values.poste,
            nom: values.nom,
            prenom: values.prenom,
            email: values.email,
            tel: values.tel,
            filename: cvpath
          }
      })
      .then((e) => {
        setIsSuccess(true)
        form.resetFields()
        setshowDndList(false)
      })
      .catch(function (erreur) {
          setIsError(true)
      });
      confirmFocus();
    }}
    )  
  }

  const confirmFocus = () => {
    confirm.current.focus()
  }

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const verifyCaptcha = (response) => {
    if (response) {
      setCaptchaValidate(true)
    }
  }

  const props = {
    name: 'file',
    multiple: true,
    showUploadList: showDndList,
    action: `${process.env.REACT_APP_SERVER}/upload`,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        setCvpath(info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} Fichier envoyé avec succés.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} Erreur lors de l'envoi du fichier.`);
      }
    },
  };

  return (
    <div className="formulaire_candidature">
    <Form
      name="basic"
      size="large"
      onSubmit={handleSubmit}
    >
        <Row gutter={18}>
          <Col span={24}>
          {isSpontane ? (
            <Form.Item>
              {getFieldDecorator('poste', {
                      rules: [{ required: true, message: 'Séléctionner un poste svp!' }],
                      })(
            <Select placeholder={offre.title ? offre.title : 'Postes'} size="large" className="input-contact" initialValue=""> 
            <Option value="postes">Postes ...</Option>
            <Option value="developpeur">Développeur</Option>
            <Option value="marketing">Marketing</Option>
            <Option value="direction">Direction</Option>
          </Select>
                      )}
          </Form.Item>
          ):
          (null)}
        
          </Col>
        </Row>
        <Row gutter={18}>
          <Col xs={24} lg={12}>
          <Form.Item>
          {getFieldDecorator('prenom', {
                      rules: [{ required: true, message: 'Entrer votre prénom svp!' }],
                      })(
          <Input placeholder="prénom" size="large" className="input-contact" initialValue=""/>
                      )}
        </Form.Item>
          </Col>
          <Col  xs={24} lg={12}>
          <Form.Item>
          {getFieldDecorator('nom', {
                      rules: [{ required: true, message: 'Entrer votre nom svp!' }],
                      })(
          <Input placeholder="nom" size="large" style={{paddingRight: 0}} className="input-contact" initialValue=""/>
                      )}
        </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col xs={24} lg={12}>
          <Form.Item>
          {getFieldDecorator('tel', {
                      rules: [{ required: true, message: 'Entrer votre nom svp!' },
                    {pattern: /^(01|02|03|04|05|06|07|08|09)[0-9]{8}$/i,
                    message: "le numéro n'est pas valide",}],
                      })(
          <Input placeholder="téléphone" size="large" className="input-contact" initialValue=""/>
                      )}
        </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
          <Form.Item>
          {getFieldDecorator('email', {
                      rules: [{ required: true, message: 'Entrer votre nom svp!' },
                    {pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'votre email n\'est pas valide!',}],
                      })(
          <Input placeholder="email" size="large" className="input-contact" initialValue=""/>
                      )}
        </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col xs={24} md={24}>
            <DragnDrop getFieldDecorator={getFieldDecorator} props={props} cvpath={cvpath}/>
          {/* <Form.Item>
          {getFieldDecorator('dragger', {
            valuePropName: 'file',
            getValueFromEvent: normFile,
          })(
            <Upload.Dragger action={`${process.env.REACT_APP_SERVER}/upload`}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">Cliquez ou glissez-déposez votre CV</p>
            </Upload.Dragger>,
          )}
        </Form.Item> */}
          </Col>
        </Row>
        { isSuccess && (<Alert
    message="Candidature envoyée !"
    description="Nous vous remercions et vous recontacterons au plus vitte. "
    type="success"
    banner="true"
    closable="true"
    showIcon
    style= {{ marginTop: 20 }}
    ref={confirm}
  />)}
  { isError && (<Alert
    message="Une erreur est survenue !"
    description="Nous vous remercions de réessayer ou de nous contacter si cela se reproduit. "
    type="error"
    banner="true"
    closable="true"
    showIcon
    ref={confirm}
  />)}
        <Row>
        <Form.Item style={{textAlign: 'center'}}>
          <Button htmlType="submit" size="large" className="input-contact">
          Soumettre ma candidature
          <CheckOutlined />
          </Button>
        </Form.Item>
        {/* {recaptchaWidget && (<Recaptcha
                    sitekey="6LezvqUZAAAAABQoLymElOwR4V9rI_N67_Kzh2Mn"
                    render="explicit"
                    onloadCallback={()=>{console.log('ReCapthca chargé avec succés !')}}
                    verifyCallback={verifyCaptcha}
                  />)} */}
        </Row>
    </Form>
  </div>
  );
};

const WrapperFormCandidature = Form.create({name: 'form_candidature'})(FormCandidature)

export default WrapperFormCandidature;