import React from 'react';
import { Card, Col, Row, Divider } from 'antd';
import { useMediaQuery } from 'react-responsive';
import logoBoxtolead from '../../assets/images/logo_boxtolead.webp';
import logoBoxtoleadBis from '../../assets/images/safari/logo_boxtolead.png';
import logoFindee from '../../assets/images/logo_findee.png';
import Group_55 from '../../assets/images/icons/training_icon.png';
import Group_56 from '../../assets/images/icons/dev_sur_mesure_icon_new.png';
import Group_57 from '../../assets/images/icons/software_icon_new.png';
import Screens from '../../assets/images/icons/screens.png';
import Puzzle from '../../assets/images/icons/puzzle.png';
import Paper from '../../assets/images/icons/paper.png';
import Chart from '../../assets/images/icons/chart.png';
import Brain from '../../assets/images/icons/brain.png';
import PC from '../../assets/images/icons/pc.png';
import Bdd from '../../assets/images/icons/bdd.png';
import Chip1 from '../../assets/images/icons/chip1.png';
import '../../CSS/offres.css';
import { Link } from 'react-router-dom';

const Offres = props => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  return (
    <div className="offres-accueil">
      <div style={{ backgroundColor: '#fdfdfd', height: 1 }}></div>
      <div style={{ position: 'relative', zIndex: 2, backgroundColor: '#fdfdfd' }}>
        <h1 id="offre_barre"> Nos offres </h1>
      </div>
      <Row
        type="flex"

        style={{ paddingBottom: 15 }}
      >
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 6, offset: 2 }} id="col-offres">
          <Row
            type="flex"
            style={{ justifyContent: 'center', marginBottom: '10px' }}
          >
            <img src={Group_55} alt="conseil et formation" style={{ objectFit: 'contain', height: 120, width: 100 }} />
          </Row>
          <Link to="/conseil">
            <Card
              title="CONSEIL & FORMATIONS"
              className="card-offre-accueil"
              id="card-offres-accueil-1"
              bordered
              headStyle={{ backgroundColor: '#063AA9', paddingTop: 15, paddingBottom: 15, color: 'white', fontSize: 20 }}
            >
              <div
                style={{
                  fontSize: '16px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  marginTop: 5
                }}
              >
                <img
                  src={Screens}
                  alt="transformation digitale"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Transformation digitale</p>
                <Divider style={{ height: 2 }} />
                <img
                  src={Chart}
                  alt="attaquer de nouveaux marches"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Attaquer de nouveaux marchés </p>
                <Divider style={{ height: 2 }} />
                <img
                  src={Puzzle}
                  alt="optimiser vos processus internes"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Optimiser vos processus internes</p>
                <Divider style={{ height: 2 }} />
                <img
                  src={Paper}
                  alt="former vos collaborateurs"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Former vos collaborateurs</p>
              </div>
            </Card>
          </Link>
        </Col>

        <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 6, offset: 1 }} id="col-offres">
          <Row
            type="flex"
            style={{ justifyContent: 'center', marginBottom: '10px' }}
          >
            <img src={Group_56} alt="genie logiciel et developpement sur mesure" style={{ objectFit: 'contain', height: 120, width: 100 }} />
          </Row>
          <Link to="/genie-logiciel">
            <Card
              title={"GÉNIE LOGICIEL & \n DÉVELOPPEMENT SUR MESURE"}
              className="card-offre-accueil"
              id="card-offres-accueil-1"
              headStyle={{ background: '#063AA9', color: 'white', fontSize: 20 }}
              bordered
            >
              <div
                style={{
                  fontSize: '16px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  marginTop: 5
                }}
              >
                <img
                  src={PC}
                  alt="marketplaces et applications web"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Marketplaces & Applications Web</p>
                <Divider style={{ height: 2 }} />
                <img
                  src={Bdd}
                  alt="gestion de base de donnees"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Gestion de base de données </p>
                <Divider style={{ height: 2 }} />
                <img
                  src={Chip1}
                  alt="systeme embarques"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Systèmes embarqués</p>
                <Divider style={{ height: 2 }} />
                <img
                  src={Brain}
                  alt="algorithmie predictive"
                  width="40px"
                  height="40px"
                />
                <br />
                <p style={{ marginTop: 5, marginBottom: 5 }}>Algorithme prédictif </p>
              </div>
            </Card>
          </Link>
        </Col>

        <Col xs={{ span: 20, offset: 2 }} md={{ span: 16, offset: 4 }} lg={{ span: 6, offset: 1 }}>
          <Row
            type="flex"
            style={{ justifyContent: 'center', marginBottom: '10px' }}
          >
            <img src={Group_57} alt="editeur de logiciel" style={{ objectFit: 'contain', height: 120, width: 100 }} />
          </Row>
          <Link to="/editeur-logiciel">
            <Card
              title="EDITEUR DE LOGICIELS"
              bordered
              className="card-offre-accueil"
              id="card-offres-accueil-3"
              headStyle={{ background: '#063AA9', paddingTop: 15, paddingBottom: 15, color: 'white', fontSize: 20 }}
            >
              <div
                className="card-offres-3"
              >
                <img
                  className="logo"
                  src={logoFindee}
                  alt="findee"
                  width="150px"
                  height="50px"
                  style={{ margin: 'auto', marginTop: 33, objectFit: 'contain' }}
                />

                <p style={{ marginTop: 30 }}>La prospection réinventée par l'Intelligence Artificielle.</p>
                <Divider style={{ marginBottom: '45px', marginTop: '55px', height: 2 }} />
                <img
                  className="logo"
                  src={logoBoxtolead}
                  src={logoBoxtoleadBis}
                  alt="boxtolead"
                  width="250px"
                  height="50px"
                  style={{ margin: 'auto', objectFit: 'contain' }}
                />

                <p style={{ marginTop: 30 }}>La prospection automatisée pour événements et salons.</p>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default Offres;
