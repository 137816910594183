import React from 'react';
import styled from 'styled-components'

const Bandeau = ({image, title, text}) => {
  
  const Image = styled.div`
    background-image: url(${image});
    background-position: center;
    background-size: cover;
    filter: brightness(60%);
    width: 100%;
    position: relative;
    height: 400px;
    margin-top: -25px;

    @media (max-width: 900px) {
      height: 270px
    }
    `
  const Title = styled.h1`
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    color: white;
    position: absolute;
    top: 190px;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;

    @media (max-width: 900px) {
      font-size: 30px;
      top: 120px
    }
  `

  const Text = styled.h2`
    font-size: 20px;
    width: 100%;
    text-align: center;
    color: white;
    position: absolute;
    top: 275px;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;

    @media (max-width: 900px) {
      font-size: 15px;
      top: 170px;
    }
  `

  return (
    <>
      <Image/>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </>
  )
}
export default Bandeau;